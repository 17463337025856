import React from 'react';
import UsersForm from '../../../components/forms/UsersForm';
import { PageBox } from '../../../components/pageBox';
import { PageHeader } from '../../../components/PageHeader';
import { ReturnButton } from '../../../components/buttons/ReturnButton';

export default function UsersCreatePage() {
  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar para Usuários" />
        <PageHeader title="Novo usuário" />
        <UsersForm />
      </div>
    </PageBox>
  );
}
