/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { PageHeader } from '../../components/PageHeader';
import { IDataPagination, IPagination } from '../../types/shared';
import { DataTable } from '../../components/datatable';
import { TableBox } from '../../components/tableBox';
import { InputForm } from '../../components/inputs/forms/InputForm';
import { ISympla, useSympla } from '../../hooks/useSympla';
import { formatDate } from '../../utils/FormatFunctions';
import { ActionsButton } from '../../components/buttons/ActionsButton';

export function SymplaPage() {
  const { getSymplas } = useSympla();

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
    }),
  );

  const filtersFormMethods = useForm<{
    search: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values =>
      setFilters(oldState => ({
        ...oldState,
        search: values.search,
        page: 1,
      })),
    500,
  );

  const queryKey = ['Symplas', Object.fromEntries([...Array.from(filters)])];

  const { data, isFetching } = useQuery<IDataPagination<ISympla[]>>({
    queryKey,
    queryFn: getSymplas,
  });

  const columns: ColumnDef<ISympla>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'name',
      header: 'name',
    },
    {
      accessorKey: 'reference_id',
      header: 'ID Sympla',
    },
    {
      accessorKey: 'start_date',
      header: 'Inicío',
      cell: ({ row }) => {
        const value = row.original.start_date;
        return formatDate(value);
      },
    },
    {
      accessorKey: 'end_date',
      header: 'Fim',
      cell: ({ row }) => {
        const value = row.original.end_date;
        return formatDate(value);
      },
    },
    {
      accessorKey: 'host.name',
      header: 'Host',
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const company = row.original;
        return (
          <ActionsButton aria-label="actions">
            <Link to={`${company.id}`}>
              <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                <Text>Ver mais </Text>
              </Button>
            </Link>
          </ActionsButton>
        );
      },
    },
  ];

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  return (
    <Box>
      <PageHeader title="Sympla" />
      <TableBox>
        <FormProvider {...filtersFormMethods}>
          <div className="flex w-fit flex-row gap-4">
            <InputForm
              name="search"
              placeholder="Buscar"
              boxShadow="0px 2px 4px #1E293B0D"
              controlMb={0}
            />
          </div>
        </FormProvider>

        <div className="border border-b-divider" />

        <DataTable<ISympla>
          columns={columns}
          data={data?.data ?? []}
          pagination={data?.pagination ?? ({} as IPagination)}
          emptyMsg="Nenhum evento encontrada."
          loadingMsg="Carregando Eventos..."
          isFetching={isFetching}
        />
      </TableBox>
    </Box>
  );
}
