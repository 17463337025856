import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import _ from 'lodash';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { IPermissions, usePermissions } from '../../../hooks/usePermissions';
import SelectInputForm from '../../inputs/forms/SelectInputForm';
import { IData, IPagination } from '../../../types/shared';
import { IGroup, useGroups } from '../../../hooks/useGroups';

const schema = yup.object().shape({
  nome: yup.string().required('Este campo é obrigatório!'),
  permissoes: yup
    .array<IPermissions>()
    .min(1, 'Este campo é obrigatório!')
    .required('Este campo é obrigatório!'),
});

interface IForm {
  nome: string;
  permissoes: IPermissions[];
}

interface IDefaultValues extends IForm {
  id?: number;
}

interface GroupsFormProps {
  defaultValues?: IDefaultValues;
}

export default function GroupsForm({ defaultValues }: GroupsFormProps) {
  const navigate = useNavigate();
  const { createGroup, updateGroup, addGroupPermissions } = useGroups();
  const { getPermissions } = usePermissions();
  const { mutateAsync } = useMutation({
    mutationFn: defaultValues ? updateGroup : createGroup,
  });
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { mutateAsync: mutateAsyncPermissions } = useMutation({
    mutationFn: addGroupPermissions,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: defaultValues?.nome ?? '',
      permissoes: defaultValues?.permissoes ?? [],
    },
  });

  const { data, isFetching } = useQuery<IData<IPermissions[]>>({
    queryFn: getPermissions,
    queryKey: ['permissionsSelect', { page: 1, size: 30, search: '' }],
  });
  const { items: permissions } = data?.data ?? { items: [], ...({} as IPagination) };

  const onSubmit = async (values: IForm) => {
    try {
      const response = await mutateAsync({
        ...values,
        permissoes: _,
        id: defaultValues?.id,
      } as unknown as IGroup);
      await mutateAsyncPermissions({
        id: response.data.id as number,
        permissoes: values.permissoes.map(item => ({ permissao_id: item.id })),
      });
      toast.success(`Grupo ${defaultValues ? 'editado' : 'criado'} com sucesso!`, {
        position: 'top-right',
      });
      navigate('/configuracoes/grupos', { replace: true });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  useEffect(() => {
    if (defaultValues?.nome) {
      formMethods.reset(defaultValues);
    }
  }, [defaultValues]);

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader
          title="Grupos"
          subtitle="Crie grupos de trabalho e gerencie as permissões de acesso de cada grupo."
        />

        <FormBox>
          <FormProvider {...formMethods}>
            <InputForm name="nome" label="Nome" placeholder="Nome do usuário" />

            <SelectInputForm<IPermissions>
              name="permissoes"
              label="Permissões gerais"
              isMulti
              closeMenuOnSelect={false}
              options={permissions}
              getOptionLabel={item => (item as IPermissions).nome}
              getOptionValue={item => String((item as IPermissions).id)}
              queryKeyName="permissionsSelect"
              getOptions={getPermissions}
              isLoading={isFetching}
            />
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="flex justify-between">
        <Button variant="outline">Cancelar</Button>
        <Button type="submit">Salvar</Button>
      </div>
    </form>
  );
}
