import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';

export interface IPermissions {
  id: number;
  nome: string;
  codigo: string;
}

interface IPermissionsFilter {
  permissionsId: string;
}

export const usePermissions = () => {
  const getPermissions = async (key: QueryFunctionContext): Promise<IData<IPermissions[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<IPermissions[]>>('/api/v1/permissoes/', {
      params: filters,
    });
    return res.data;
  };

  const getPermission = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<IPermissions>> => {
    const { permissionsId } = key.queryKey[1] as IPermissionsFilter;
    const res = await api.get<IDataNoPagination<IPermissions>>(
      `/api/v1/permissoes/${permissionsId}/`,
    );
    return res.data;
  };

  const createPermission = async (data: IPermissions): Promise<IData<IPermissions>> => {
    const res = await api.post<IData<IPermissions>>('/api/v1/permissoes/', data);
    return res.data;
  };

  const updatePermission = async (data: IPermissions): Promise<IData<IPermissions>> => {
    const res = await api.put<IData<IPermissions>>(`/api/v1/permissoes/${data.id}/`, data);
    return res.data;
  };

  const deletePermission = async (permissionsId: string) => {
    const res = await api.delete(`/api/v1/permissoes/${permissionsId}/`);
    return res.data;
  };

  return {
    getPermissions,
    getPermission,
    createPermission,
    updatePermission,
    deletePermission,
  };
};
