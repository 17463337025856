/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import DatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import ReactInputMask from 'react-input-mask';
import _ from 'lodash';

registerLocale('pt-BR', ptBR);

interface DateInputFormProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string | undefined | any;
  label?: string;
  dateStart?: string;
  dateEnd?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  minDate?: any;
  mask?: string;
  controlMb?: number;
}

export const DateInputForm = ({
  name,
  label,
  dateStart,
  dateEnd,
  selectsStart = false,
  selectsEnd = false,
  isRequired = false,
  isClearable = false,
  minDate = null,
  mask = '99/99/9999',
  controlMb,
  ...rest
}: DateInputFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = _.get(errors, name);
  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={controlMb ?? 5}>
      {label && (
        <FormLabel fontWeight="bold" mb={1} mr={3} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            placeholderText="DD/MM/YYYY"
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            wrapperClassName="w-full"
            startDate={selectsStart ? field.value : dateStart ?? null}
            endDate={selectsEnd ? field.value : dateEnd ?? null}
            minDate={minDate}
            onChange={date => field.onChange(date)}
            selected={field?.value ? new Date(field.value) : null}
            showTimeSelect={false}
            todayButton="Hoje"
            customInput={<Input id={name} as={ReactInputMask} mask={mask} {...field} />}
            dropdownMode="select"
            isClearable={isClearable}
            shouldCloseOnSelect
            {...rest}
          />
        )}
      />
      {error && <span className="text-sm text-red-500">{String(error?.message)}</span>}
    </FormControl>
  );
};
