/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import moment from 'moment';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { ImageInputForm } from '../../inputs/forms/ImageInputForm';
import { DateInputForm } from '../../inputs/forms/DateInputForm';
import { IProfile, useProfile } from '../../../hooks/useProfile';
import { useAuth } from '../../../providers/auth';

const schema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  nome_social: yup.string().required('Campo obrigatório'),
  cpf: yup.string().required('Campo obrigatório'),
  data_nascimento: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  image: yup.mixed().nullable(),
});

interface IForm {
  nome: string;
  nome_social: string;
  cpf: string;
  data_nascimento: string;
  email: string;
  image?: any;
}

interface ProfileFormProps {
  defaultValues?: IForm;
}

export default function ProfileForm({ defaultValues }: ProfileFormProps) {
  // eslint-disable-next-line no-unused-vars
  const [isUpping, setIsUpping] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [images, setImages] = useState<any>(null);
  const { getUser, user } = useAuth();

  const { updateProfile } = useProfile();

  const { mutateAsync } = useMutation({
    mutationFn: updateProfile,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: user?.nome ?? '',
      nome_social: user?.nome_social ?? '',
      cpf: user?.cpf ?? '',
      data_nascimento: user?.data_nascimento ?? '',
      email: user?.email ?? '',
      image: defaultValues?.image?.medium ?? null,
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync({
        ...values,
        data_nascimento: moment(values.data_nascimento).format('yyyy-MM-DD'),
      } as unknown as IProfile);
      await getUser();
      toast.success(`Perfil editado com sucesso!`, {
        position: 'top-right',
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader title="Segurança" subtitle="Informe sua senha atual para criação de uma nova" />

        <FormBox>
          <FormProvider {...formMethods}>
            <ImageInputForm
              defaultVal={defaultValues?.image}
              setIsUpping={setIsUpping}
              setImages={setImages}
              label="Sua foto"
              name="image"
              useAvatar
              AvatarSize="100px"
            />
            <InputForm name="nome" label="Nome completo" placeholder="Seu nome" isRequired />
            <InputForm
              name="nome_social"
              label="Nome de preferência (opcional)"
              placeholder="Como podemos te chamar"
            />
            <InputForm name="email" label="E-mail" placeholder="mail@mail.com" isRequired />
            <InputForm
              name="cpf"
              label="CPF"
              placeholder="033.300.033-00"
              mask="999.999.999-999"
              isRequired
            />
            <DateInputForm
              name="data_nascimento"
              label="Data de Nascimento"
              placeholderText="21/2/1988"
              isRequired
            />
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="flex justify-between">
        <Button variant="outline">Cancelar</Button>
        <Button type="submit">Salvar</Button>
      </div>
    </form>
  );
}
