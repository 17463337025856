/* eslint-disable no-unused-vars */
import React, { FC, ReactElement, createContext, useCallback, useContext, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import DialogModal from '../components/DialogModal';

interface DialogProps {
  title: string;
  status: 'info' | 'success' | 'warning' | 'error';
  description?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

interface Props {
  children: ReactElement | ReactElement[];
}

type DialogContextData = (_: DialogProps) => void;

export const DialogContext = createContext<DialogContextData>(null as unknown as DialogContextData);

export const DialogProvider: FC<Props> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [data, setData] = useState<DialogProps>({
    title: '',
    status: 'info',
    onCancel() {},
    onConfirm() {},
  });

  const showDialog = useCallback((props: DialogProps) => {
    setData(props);
    onOpen();
  }, []);

  return (
    <DialogContext.Provider value={showDialog}>
      {children}

      <DialogModal
        isOpen={isOpen}
        status={data.status}
        onCancel={() => {
          onClose();
          data?.onCancel?.();
        }}
        onConfirm={() => {
          data?.onConfirm?.();
          onClose();
        }}
        title={data.title}
        cancelLabel={data.cancelLabel}
        confirmLabel={data.confirmLabel}
        description={data.description}
      />
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext<DialogContextData>(DialogContext);

  if (!context) {
    throw new Error('useDialog must be used within a ToastProvider');
  }

  return context;
};
