import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Layout from '../components/layout';
import { useAuth } from '../providers/auth';

export const RequireAuth = () => {
  const { signed, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading</div>;
  }

  return signed ? (
    <div className="min-h-[100vh] bg-background">
      <Layout>
        <Outlet />
      </Layout>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
