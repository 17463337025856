/* eslint-disable no-unused-vars */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'sonner';
// import { useAuth } from '../../../../providers/auth';
import { Stack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { PinInputForm } from '../../../inputs/forms/PinInputForm';
import { FormButton } from '../../../buttons/FormButton';
import { IValidateCode, useRecover } from '../../../../hooks/useRecover';

const schema = yup.object().shape({
  code: yup.string().required('Campo obrigatório.'),
});

interface IForm {
  code: string;
}

interface PinStepProps {
  nextStep: () => void;
  userEmail: string;
  setApprovalHash: (approval_hash: string) => void;
}

export default function PinStepForm({ nextStep, userEmail, setApprovalHash }: PinStepProps) {
  const { validateCode } = useRecover();

  const { mutateAsync } = useMutation({
    mutationFn: validateCode,
  });
  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      const response = await mutateAsync({
        email: userEmail,
        code: values.code,
      } as unknown as IValidateCode);
      setApprovalHash(response.data.approval_hash);
      nextStep();
      toast.success('Login realizado com sucesso!', { position: 'top-center' });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
        { position: 'top-center' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <Stack direction="row" spacing={4} justify="center">
          <PinInputForm name="code" label="Código" numberOfFields={6} />
        </Stack>

        <FormButton mt={4} isLoading={formMethods.formState.isSubmitting} type="submit" w="100%">
          Confirmar
        </FormButton>
      </FormProvider>
    </form>
  );
}
