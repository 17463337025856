import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { IPermissions, usePermissions } from '../../../hooks/usePermissions';

const schema = yup.object().shape({
  nome: yup.string().required('Este campo é obrigatório!'),
  codigo: yup.string().required('Este campo é obrigatório!'),
});

interface IForm {
  nome: string;
  codigo: string;
}

interface PermissionsFormProps {
  defaultValues?: IForm;
}

export default function PermissionsForm({ defaultValues }: PermissionsFormProps) {
  const navigate = useNavigate();
  const { createPermission, updatePermission } = usePermissions();

  const { mutateAsync } = useMutation({
    mutationFn: defaultValues ? updatePermission : createPermission,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: defaultValues?.nome ?? '',
      codigo: defaultValues?.codigo ?? undefined,
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync(values as unknown as IPermissions);
      toast.success(`Permissão ${defaultValues ? 'editada' : 'criada'} com sucesso!`, {
        position: 'top-right',
      });
      navigate('/configuracoes/permissoes', { replace: true });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <FormProvider {...formMethods}>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
          <FormHeader title="Permissão" subtitle="Informe os dados da permissão" />

          <FormBox>
            <InputForm name="nome" label="Nome" placeholder="Digite o nome" />
            <InputForm name="codigo" label="Código" placeholder="Digite o código" />
          </FormBox>
        </div>

        <div className="mt-3 border border-b-divider" />

        <div className="flex justify-between">
          <Button variant="outline">Cancelar</Button>
          <Button type="submit">Salvar</Button>
        </div>
      </FormProvider>
    </form>
  );
}
