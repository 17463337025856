/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Image, Avatar } from '@chakra-ui/react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { Controller, useFormContext } from 'react-hook-form';
import { handleImgError } from '../../../../utils/HandleFunctions';

interface ImageInputFormProps {
  defaultVal: any;
  setImages: (image: any) => void;
  setIsUpping: (state: boolean) => void;
  name: string;
  label?: string;
  useAvatar?: boolean;
  isRequired?: boolean;
  AvatarSize?: string;
}

export const ImageInputForm = ({
  defaultVal,
  setImages,
  setIsUpping,
  name,
  label,
  useAvatar = false,
  isRequired,
  AvatarSize,
}: ImageInputFormProps) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const convert2base64 = file => {
    setIsUpping(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImages(reader.result);
    };
    reader.readAsDataURL(file);
    setIsUpping(false);
  };

  useEffect(() => {
    if (watch(name)?.length > 0 && typeof watch(name) === 'object') {
      convert2base64(watch(name)[0] as any);
      const objectUrl = URL.createObjectURL(watch(name)[0]);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
  }, [watch(name)]);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={5}>
      <FormLabel fontWeight="semiBold" mb={2} htmlFor={name}>
        {label}
      </FormLabel>
      <div className="flex flex-col items-center lg:flex-row">
        {useAvatar ? (
          <Avatar
            mb="15px"
            boxShadow="0px 3px 6px #00000065"
            w={AvatarSize ?? '200px'}
            h={AvatarSize ?? '200px'}
            src={preview ?? defaultVal?.original}
          />
        ) : (
          (defaultVal?.original || preview) && (
            <Image
              mb="15px"
              boxShadow="0px 3px 6px #00000065"
              borderRadius="10px"
              w="150px"
              onError={handleImgError}
              src={preview ?? defaultVal?.original}
            />
          )
        )}
        <div className="ml-2 flex items-center">
          <label htmlFor={name} style={{ display: 'block', width: '200px', alignItems: 'end' }}>
            <div className="flex h-10 w-52 cursor-pointer items-center justify-center rounded-md border border-secondary bg-transparent p-2.5 uppercase transition duration-200 ease-in-out hover:opacity-60 ">
              <span className="text-xs font-bold text-secondary">Carregar nova foto</span>
              <MdOutlineFileUpload className="ml-2 text-2xl text-secondary" />
            </div>
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <input
                id={name}
                type="file"
                hidden
                accept="image/*"
                onChange={e => {
                  field.onChange(e);
                  setValue(name, e.target.files);
                }}
              />
            )}
          />
        </div>
      </div>

      {errors[name] && <FormErrorMessage>{String(errors[name]?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
