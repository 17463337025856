import React from 'react';
import { PageBox } from '../../components/pageBox';
import { PageHeader } from '../../components/PageHeader';
import CompanyForm from '../../components/forms/CompanyForm';

export default function CompanyPage() {
  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <PageHeader title="Configurações da empresa" />
        <CompanyForm />
      </div>
    </PageBox>
  );
}
