/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import { PencilSimpleLine, Trash, Users } from 'phosphor-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { PageHeader } from '../../components/PageHeader';
import { IData, IPagination } from '../../types/shared';
import { DataTable } from '../../components/datatable';
import { TableBox } from '../../components/tableBox';
import { EmptyData } from '../../components/emptyData';
import { colors } from '../../theme/colors';
import { IGroup, useGroups } from '../../hooks/useGroups';
import { InputForm } from '../../components/inputs/forms/InputForm';
import { ActionsButton } from '../../components/buttons/ActionsButton';
import { useDialog } from '../../providers/dialog';

export function GroupsPage() {
  const showDialog = useDialog();
  const { getGroups } = useGroups();
  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
    }),
  );
  // const { mutateAsync } = useMutation({
  //   mutationFn: deleteGroup,
  // });

  const filtersFormMethods = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values => setFilters(oldState => ({ ...oldState, ...values, page: 1 })),
    500,
  );

  const queryKey = ['groups', Object.fromEntries([...Array.from(filters)])];
  const { data, isFetching } = useQuery<IData<IGroup[]>>({
    queryKey,
    queryFn: getGroups,
  });

  const { items: groups, ...pagination } = data?.data ?? { items: [], ...({} as IPagination) };

  const columns = useMemo<ColumnDef<IGroup>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const group = row.original;

          return (
            <ActionsButton aria-label="actions">
              <Link to={`${group.id}/editar`}>
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <PencilSimpleLine size={20} weight="bold" />
                  <Text>Editar</Text>
                </Button>
              </Link>

              <Button
                variant="unstyled"
                p="6px"
                display="flex"
                alignItems="center"
                gap={2}
                onClick={() =>
                  showDialog({
                    status: 'warning',
                    title: `Tem certeza que deseja remover o grupo ${group.nome}?`,
                    description: 'Essa ação não pode ser desfeita.',
                    cancelLabel: 'Cancelar',
                    // async onConfirm() {
                    //   await mutateAsync(String(group?.id));
                    //   setFilters(oldState => ({ ...oldState, page: 1 }));
                    // },
                  })
                }
              >
                <Trash size={20} weight="bold" />
                <Text>Remover</Text>
              </Button>
            </ActionsButton>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  return (
    <Box>
      <PageHeader
        title="Grupos"
        subtitle="Crie grupos de trabalho e gerencie as permissões de acesso de cada grupo."
      />

      {!filters.get('search') && groups.length === 0 && !isFetching ? (
        <EmptyData
          title="Não existem Grupos"
          subtitle="Cadastre os grupos"
          buttonLabel="Adicionar novo grupo"
          icon={<Users size={90} color={colors.primary} />}
        />
      ) : (
        <TableBox>
          <div className="flex justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <form className="flex">
                <InputForm
                  name="search"
                  placeholder="Buscar"
                  boxShadow="0px 2px 4px #1E293B0D"
                  controlMb={0}
                />
              </form>
            </FormProvider>

            <Link to="criar">
              <Button>Novo grupo</Button>
            </Link>
          </div>

          <div className="border border-b-divider" />

          <DataTable
            columns={columns}
            data={groups}
            pagination={pagination}
            emptyMsg="Nenhum grupo encontrado."
            loadingMsg="Carregando grupos..."
            isFetching={isFetching}
          />
        </TableBox>
      )}
    </Box>
  );
}
