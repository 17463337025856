import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdDashboard, MdOutlineInsertPageBreak } from 'react-icons/md';
import { BsFiletypeXml } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa6';
import { FaNotEqual } from 'react-icons/fa';
import { PageBox } from '../../../components/pageBox';
import { DashboardCard } from '../../../components/dashboardCard';
import { FormBox } from '../../../components/formBox';

export default function ModulesDashBoardIntegrationPage() {
  const navigate = useNavigate();
  return (
    <PageBox>
      <FormBox>
        <div className="grid grid-cols-1 gap-6 pb-14 md:grid-cols-2 lg:grid-cols-2 lg:pb-0">
          <DashboardCard
            title="Dashboard"
            value=""
            onClick={() => navigate('/modulesHome/integration/dashboard')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#7AD8FF] p-3">
              <MdDashboard color="#008CCC" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="Quebra de Sequência"
            value=""
            onClick={() => navigate('/modulesHome/integration/sequenceBreak')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#DFF3FF] p-3">
              <MdOutlineInsertPageBreak color="#008CCC" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="Análise do XML NFS-e"
            value=""
            onClick={() => navigate('/modulesHome/integration/xmlAnalysis')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#FFE1C6] p-3">
              <BsFiletypeXml color="#F2994A" className="size-12" />
            </div>
          </DashboardCard>

          <DashboardCard
            title="Análise de Faturamento"
            value=""
            onClick={() => navigate('/modulesHome/integration/billingAnalysis')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#ABEDC6] p-3">
              <FaFileInvoiceDollar color="#219653" className="size-12" />
            </div>
          </DashboardCard>
          <DashboardCard
            title="API X SIEG"
            value=""
            onClick={() => navigate('/modulesHome/integration/apixsieg')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#fc6c4f] p-3">
              <FaNotEqual color="#ff4e2a" className="size-12" />
            </div>
          </DashboardCard>
          {/* <DashboardCard
            title="PLANEJAMENTO TRIBUTÁRIO"
            value="Rhoncus semper lectus donec sapien est morbi felis risus."
            onClick={() => navigate('/modulesDashboard/taxPlanning')}
          >
            <div className="flex items-center justify-center rounded-full bg-[#C5B2D4] p-3">
              <IoCalculatorOutline color="#47305A" className="size-12" />
            </div>
          </DashboardCard> */}
        </div>
      </FormBox>
    </PageBox>
  );
}
