import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'phosphor-react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface ReturnButtonProps extends ButtonProps {
  label?: string;
  previousPage?: string;
}

export const ReturnButton = ({ previousPage, label, ...props }: ReturnButtonProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-start">
      <Button
        variant="link"
        color="text.secondary"
        onClick={() => (previousPage ? navigate(`/${previousPage}`) : navigate(-1))}
        leftIcon={<ArrowLeft size={20} />}
        {...props}
      >
        {label}
      </Button>
    </div>
  );
};
