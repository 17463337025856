import { ComponentStyleConfig } from '@chakra-ui/react';

export const buttonStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '500',
    borderRadius: '6px',
    outline: 0,
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      h: '40px',
    },
    md: {
      fontSize: 'md',
      px: 10,
      h: '44px',
    },
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'primary',
      color: 'primary',
      _hover: {
        color: 'primary',
        bg: 'button.outline_hover',
      },
      _active: {
        color: 'primary',
        bg: 'button.outline_active',
      },
    },

    solid: {
      border: 0,
      bg: 'primary',
      color: 'white',
      _hover: {
        color: 'primary',
        border: '2px solid',
        borderColor: 'primary',
        fontWeight: 'bold',
        bg: 'body',
      },
      _active: {
        bg: 'button.active',
      },
    },

    solid_secondary: {
      bg: 'secondary',
      color: 'white',
      fontWeight: 'bold',
      _hover: {
        bg: 'secondary.800',
      },
      _active: {
        bg: 'active_secondary.800',
      },
    },
    text: {
      p: 0,
      color: 'black',
      fontWeight: 'bold',
      _hover: {
        color: 'primary',
        bg: 'button.outline_hover',
      },
      _active: {
        color: 'primary',
        bg: 'button.outline_active',
      },
    },
    text_secondary: {
      color: 'secondary',
      _hover: {
        color: 'button.hover_secondary',
      },
      _active: {
        color: 'button.active_secondary',
      },
    },
    link: {
      color: 'black',
      textDecoration: 'underline',
      _hover: {
        color: 'black',
      },
      _active: {
        color: 'black',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
