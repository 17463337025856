import React, { ReactNode } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../sidebar';
import Header from '../header';

const Layout = ({ children }: { children: ReactNode }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <div className="h-full w-full bg-background">
      <div>
        <Header />
        <div className="h-16" />
      </div>
      <div className="flex h-full">
        <div className="hidden md:block">
          <Sidebar onToggle={onToggle} isOpen={isOpen} />
          <Box transition="0.5s ease" w={isOpen ? '216px' : '64px'} />
        </div>
        <div className="w-full overflow-y-auto py-8 duration-500 ease-linear">
          <div className="px-5 md:px-[55px]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
