/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Input, InputProps } from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';

interface CurrencyInputFormProps extends InputProps {
  name: string | undefined | any;
  label: string | undefined;
  prefix?: string;
  isRequired?: boolean;
}

export const CurrencyInputForm = ({
  name,
  label,
  prefix = 'R$ ',
  isRequired = false,
  ...rest
}: CurrencyInputFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);
  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <FormLabel mb={1} fontWeight="bold" htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...restField } }) => (
          <Input
            id={name}
            as={NumericFormat}
            background="body"
            onValueChange={({ floatValue }) => {
              if (typeof floatValue === 'undefined') {
                onChange('');
              } else {
                onChange(floatValue);
              }
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            prefix={prefix}
            value={parseFloat(value)}
            {...restField}
            {...rest}
          />
        )}
      />
      {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
