export const handleImgError = e => {
  e.target.src = '/no-image.jpg';
};

export const handleConvertToBase64 = async file => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};
