import React, { useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as IProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Icon,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { Eye, EyeSlash } from 'phosphor-react';
import ReactInputMask from 'react-input-mask';

interface InputProps extends IProps {
  name: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  controlMb?: number;
  labelCN?: React.ComponentProps<typeof FormLabel>['className'];
  type?: string;
  helper?: string;
  isRequired?: boolean;
  isLoading?: boolean;
  mask?: string;
  label?: string;
  buttonRight?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onInputChange?: (e?: number | string) => void;
}

export const InputForm = ({
  name,
  leftIcon,
  rightIcon,
  type = 'text',
  helper,
  labelCN,
  isRequired,
  isLoading,
  mask,
  onInputChange,
  label,
  controlMb,
  buttonRight,
  ...rest
}: InputProps) => {
  const [show, setShow] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={controlMb ?? 5}>
      {label && (
        <FormLabel className={labelCN} fontWeight="bold" mb={1} mr={3} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup alignItems="center">
        {leftIcon && (
          <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
            {leftIcon}
          </InputLeftElement>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ChakraInput
              id={name}
              w="full"
              as={!mask ? undefined : ReactInputMask}
              maskPlaceholder={null}
              mask={mask ?? ''}
              maskChar=""
              isInvalid={!!error}
              errorBorderColor="red.400"
              placeholder={rest.placeholder ?? label}
              {...rest}
              type={!show ? type ?? 'text' : 'text'}
              onChange={e => {
                let { value: inputValue } = e.target;
                if (type === 'number') {
                  inputValue = Number(e.target.value) as unknown as string;
                }
                onInputChange?.(inputValue);
                onChange(e);
              }}
              value={value}
            />
          )}
        />

        {rightIcon && (
          <InputRightElement pointerEvents="none" color="gray.300" fontSize="1.2em">
            {rightIcon}
          </InputRightElement>
        )}

        {isLoading && (
          <InputRightElement width="2.5rem" height="100%">
            <Spinner color="primary.500" />
          </InputRightElement>
        )}

        {type === 'password' && (
          <InputRightElement width="4rem" height="full" mr="-12px">
            <Icon
              onClick={() => setShow(m => !m)}
              fontSize="25px"
              cursor="pointer"
              color="green.strong"
            >
              {show ? <Eye /> : <EyeSlash />}
            </Icon>
          </InputRightElement>
        )}

        {buttonRight && buttonRight}
      </InputGroup>
      {errors && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
      {helper && (
        <Text fontSize="sm" fontWeight="500" color="text.placeholder" mt="6px">
          {helper}
        </Text>
      )}
    </FormControl>
  );
};
