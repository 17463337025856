import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OpenRoutes } from './open.routes';
import { PrivateRoutes } from './private.routes';
import { RequireAuth } from './require.auth';

const AppRouter = () => (
  <Routes>
    {OpenRoutes.map((item, key) => (
      <Route key={Number(key)} path={item.path} element={<item.component />} />
    ))}
    <Route path="/" element={<RequireAuth />}>
      {PrivateRoutes.filter(item => !item.permissionSympla).map((item, key) => {
        const Component = item.component as unknown as React.ElementType;
        const ComponentCreate = item.componentCreate as unknown as React.ElementType;
        const ComponentShow = item.componentShow as unknown as React.ElementType;
        const ComponentEdit = item.componentEdit as unknown as React.ElementType;
        return (
          <>
            <Route key={Number(key)} path={item.path} element={<Component />} />
            {ComponentCreate && <Route path={`${item.path}/criar`} element={<ComponentCreate />} />}
            {ComponentShow && <Route path={`${item.path}/:itemId`} element={<ComponentShow />} />}
            {ComponentEdit && (
              <Route path={`${item.path}/:itemId/editar`} element={<ComponentEdit />} />
            )}
          </>
        );
      })}
    </Route>
  </Routes>
);

export default AppRouter;
