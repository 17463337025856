import { Button, Text } from '@chakra-ui/react';
import { Plus } from 'phosphor-react';
import React from 'react';

interface EmptyDataProps {
  title: string;
  subtitle: string;
  buttonLabel: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export const EmptyData = ({ title, subtitle, buttonLabel, icon, onClick }: EmptyDataProps) => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center self-center md:gap-16">
        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-1">
            <Text fontWeight="bold" className="text-xl md:text-2xl">
              {title}
            </Text>
            <Text color="text.quaternary" className="text-sm">
              {subtitle}
            </Text>
          </div>

          <Button leftIcon={<Plus weight="bold" />} onClick={onClick} className="w-[80%] md:w-full">
            {buttonLabel}
          </Button>
        </div>
        <div className="rounded-full bg-subMenuBg p-5 md:p-16">{icon}</div>
      </div>
    </div>
  );
};
