export const textAreaStyle = {
  baseStyle: {
    outline: 0,
    boxShadow: 0,
    border: '2px solid primary',
    borderWidth: 1,
  },
  variants: {
    outline: {
      _focus: {
        borderColor: 'primary',
        boxShadow: 0,
      },
      _active: {
        borderColor: 'primary',
        boxShadow: 0,
      },
      _disabled: {
        opacity: 0.6,
        cursor: 'default',
      },
      _hover: {
        boxShadow: `0px 1px 0px 0px #ddd`,
      },
      addon: {
        borderColor: 'primary',
      },
    },
  },

  sizes: {
    sm: {
      field: {
        fontSize: 'sm',
        px: 6,
      },
    },
    md: {
      field: {
        fontSize: 'md',
        px: 5,
      },
    },
  },

  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
};
