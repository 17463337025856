import React from 'react';
import {
  Avatar,
  Button,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { FileLock, Lock, SignOut, User } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/auth';
import logo from '../../assets/images/logo_white.png';
import { ResponsiveMenu } from '../sidebar/ResponsiveMenu';

const Header = () => {
  const { Logout } = useAuth();

  return (
    <div className="fixed z-50 flex w-[100vw] items-center justify-between bg-primary p-2 pr-6">
      <div className="flex items-center">
        <div className="block md:hidden">
          <ResponsiveMenu />
        </div>
        <Image src={logo} w="150px" />
      </div>

      <Popover>
        <PopoverTrigger>
          <Button variant="unstyled">
            <Avatar
              size="sm"
              src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent mr="12px" p="5px" boxShadow="0px 4px 6px rgba(0, 0, 0, 0.09)">
          <PopoverCloseButton />
          <PopoverBody>
            <div className="flex flex-col items-start">
              <Link to="/perfil">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <User size={20} weight="bold" />
                  <Text>Minha Conta</Text>
                </Button>
              </Link>

              <Link to="/seguranca">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <Lock size={20} weight="bold" />
                  <Text>Segurança</Text>
                </Button>
              </Link>

              <Link to="/sessoes">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <FileLock size={20} weight="bold" />
                  <Text>Sessões</Text>
                </Button>
              </Link>

              <PopoverHeader w="full" />

              <Button
                variant="unstyled"
                p="6px"
                display="flex"
                alignItems="center"
                gap={2}
                onClick={Logout}
              >
                <SignOut size={20} weight="bold" />
                <Text>Sair</Text>
              </Button>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Header;
