import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

export const PageHeader = ({ title, subtitle }: PageHeaderProps) => {
  return (
    <div className="flex flex-col gap-2 pb-5">
      <Heading fontSize="24px" fontWeight="700">
        {title}
      </Heading>

      {subtitle && <Text color="secondary">{subtitle}</Text>}

      <div className="mt-3 border border-b-divider" />
    </div>
  );
};
