import React from 'react';
import { PageBox } from '../../../components/pageBox';
import { PageHeader } from '../../../components/PageHeader';
import { ReturnButton } from '../../../components/buttons/ReturnButton';
import PermissionsForm from '../../../components/forms/PermissionsForm';

export default function PermissionsCreatePage() {
  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar para Permissões" />
        <PageHeader title="Nova Permissão" />
        <PermissionsForm />
      </div>
    </PageBox>
  );
}
