import * as React from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';
import moment from 'moment';
import { theme } from './theme';

// locales
import './constants/locales/i18n';
import 'moment/locale/pt-br';

import AppRouter from './routes';
import { AuthProvider } from './providers/auth';
import { queryClient } from './services/queryConfig';
import { DialogProvider } from './providers/dialog';

moment.updateLocale('pt-br', null);

export function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <AuthProvider>
            <DialogProvider>
              <Toaster richColors toastOptions={{}} />
              <AppRouter />
            </DialogProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}
