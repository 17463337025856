/* eslint-disable import/no-extraneous-dependencies */
import React, { CSSProperties } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  PinInput,
  PinInputField,
  PinInputProps,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';

interface PinInputFormProps extends Omit<PinInputProps, 'children'> {
  name: string;
  label?: string;
  labelStyles?: CSSProperties;
  isRequired?: boolean;
  numberOfFields: number;
}

export const PinInputForm = ({
  name,
  label,
  labelStyles,
  isRequired = false,
  numberOfFields,
  ...rest
}: PinInputFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = _.get(errors, name);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={5}>
      <FormLabel style={labelStyles} mb={1} fontWeight="bold" htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { ...restProps } }) => (
          <PinInput type="number" variant="unstyled" {...restProps} {...rest}>
            {Array.from(Array(numberOfFields).keys()).map(item => {
              return (
                <PinInputField
                  key={item}
                  _focus={{ borderColor: 'text.tertiary', outline: 'none' }}
                  sx={{
                    width: ['3.7rem', '4rem', '4rem'],
                    marginRight: ['3px', '3px', '10px'],
                  }}
                  className="mt-1 h-10 rounded-l-lg border-2 border-inputBorder p-2 focus-visible:bg-transparent"
                />
              );
            })}
          </PinInput>
        )}
      />
      {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
