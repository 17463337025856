/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { DotsThree } from 'phosphor-react';

export const ActionsButton = ({ onClick, children, ...props }: IconButtonProps) => {
  return (
    <div className="flex justify-end">
      <Popover placement="auto-end">
        <PopoverTrigger>
          <IconButton
            borderRadius="full"
            onClick={onClick}
            icon={<DotsThree size={28} />}
            size="sm"
            mr={2}
            variant="ghost"
            {...props}
          />
        </PopoverTrigger>
        <PopoverContent p="5px" boxShadow="0px 4px 6px rgba(0, 0, 0, 0.09)" maxW="200px">
          <PopoverCloseButton />
          <PopoverBody>
            <div className="flex flex-col items-start">{children}</div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};
