import { QueryFunctionContext } from '@tanstack/react-query';
import _ from 'lodash';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';

export interface ICompany {
  id?: 0;
  dt_criacao: any;
  dt_atualizacao: any;
  nome: string;
  cnpj: string;
  razao_social: string;
  external_id: string;
  external_id_column: string;
  quebras: number;
  sem_quebras: number;
  nf_quebradas: any;
  quantidade_notas: number;
  variacao_faturamento: number;
  faturamento_anterior: number;
  faturamento_atual: number;
  cidade: any;
  uf: any;
  dt_cadastro: any;
  dt_cli_desde: any;
  dt_cli_ate: any;
  ativa: any;
  grupo_de_empresas: any;
  tags: any;
  comercial: any;
  contabil: any;
  financeiro: any;
  contas_a_pagar: any;
  encerramento_anual: any;
  fiscal: any;
  imposto_de_renda: any;
  integracao_contabil: any;
  integracao_fiscal: any;
  pessoal: any;
  relacionamento_com_cliente: any;
  societario: any;
}
export interface IBreak {
  nfce: number[];
  nfe: number[];
  nfse: number[];
}

export interface ICompanyQuebrasTotais {
  alertas?: number;
  quebras?: number;
  sem_quebras?: number;
}

export interface ISaida {
  verificado_sieg: boolean;
  sigl_est: string;
  codi_emp: string;
  nume_sai: string;
  seri_sai: string;
  codi_esp: string;
  codi_acu: string;
  dsai_sai: any;
  ddoc_sai: any;
  dser_ser: any;
  ddoc_ser: any;
  novo_ecf: string;
  valor_contabil: number;
  chave_nfe: string;
  external_id: string;
  external_id_column: string;
  intermunicipal: number;
  tributo: ITributo;
  empresa_id: number;
}

export interface ITributo {
  iss_retido: number;
  valor_iss: number;
  inss: number;
  csll: number;
  pis: number;
  cofins: number;
  valor_nfe: number;
}

interface ICompanyFilter {
  companyId: number;
}

export const useCompanies = () => {
  const getCompanies = async (key: QueryFunctionContext): Promise<IData<ICompany[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ICompany[]>>('/api/v1/empresas/', {
      params: filters,
    });
    return res.data;
  };

  const getCompany = async (key: QueryFunctionContext): Promise<IData<ICompany>> => {
    const { companyId } = key.queryKey[1] as ICompanyFilter;
    const res = await api.get<IData<ICompany>>(`/api/v1/empresas/${companyId}/`);
    return res.data;
  };

  const createCompany = async (data: ICompany): Promise<IData<ICompany>> => {
    const res = await api.post<IData<ICompany>>('/api/v1/empresas/', data);
    return res.data;
  };

  const updateCompany = async (data: ICompany): Promise<IData<ICompany>> => {
    const res = await api.put<IData<ICompany>>(`/api/v1/empresas/${data.id}/`, data);
    return res.data;
  };

  const deleteCompany = async (companyId: string) => {
    const res = await api.delete(`/api/v1/empresas/${companyId}/`);
    return res.data;
  };

  const getQuebras = async (key: QueryFunctionContext): Promise<IData<ICompany[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ICompany[]>>('/api/v1/empresas/quebras/', {
      params: filters,
    });
    return res.data;
  };

  const getQuebrasWithId = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<IBreak>> => {
    const filters = key.queryKey?.[1] ?? ({} as unknown as any);
    const res = await api.get<IDataNoPagination<IBreak>>(
      `/api/v1/empresas/${filters?.companyId}/quebras/`,
      {
        params: _.omit(filters, 'companyId'),
      },
    );
    return res.data;
  };

  const getQuebrasTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/empresas/quebras/total/', {
      params: filters,
    });
    return res.data;
  };

  const uploadCompanyFile = async ({
    file,
  }: {
    file: HTMLInputElement;
  }): Promise<IDataNoPagination<ICompany>> => {
    const formData = new FormData();
    formData.append('file', file[0]);
    const res = await api.post('/api/v1/empresas/import/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct Content-Type header for FormData
      },
    });
    return res.data;
  };

  const getCompaniesFilters = async () => {
    const res = await api.get(`/api/v1/empresas/filters/`);
    return res.data;
  };

  const getCompaniesSaidas = async (key: QueryFunctionContext): Promise<IData<ISaida[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ISaida[]>>('/api/v1/saidas/', {
      params: filters,
    });
    return res.data;
  };

  return {
    getCompanies,
    getCompany,
    getQuebras,
    getQuebrasTotais,
    createCompany,
    updateCompany,
    deleteCompany,
    uploadCompanyFile,
    getQuebrasWithId,
    getCompaniesFilters,
    getCompaniesSaidas,
  };
};
