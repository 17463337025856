import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IDataNoPagination, IDataPagination } from '../types/shared';

export interface ISympla {
  id?: number;
  reference_id: number;
  start_date: any;
  end_date: any;
  name: string;
  detail: string;
  private_event: number;
  published: number;
  cancelled: number;
  image: string;
  url: string;
  address: {
    name: string;
    address: string;
    address_num: string;
    neighborhood: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    lat: number;
    lon: number;
  };
  host: {
    name: string;
    description: string;
  };
  category_prim: {
    name: string;
  };
}

interface ISymplaFilter {
  symplaId: number;
}

export const useSympla = () => {
  const getSymplas = async (key: QueryFunctionContext): Promise<IDataPagination<ISympla[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IDataPagination<ISympla[]>>('/api/v1/sympla/events/', {
      params: filters,
    });
    return res.data;
  };

  const getSympla = async (key: QueryFunctionContext): Promise<IDataNoPagination<ISympla>> => {
    const { symplaId } = key.queryKey[1] as ISymplaFilter;
    const res = await api.get<IDataNoPagination<ISympla>>(`/api/v1/sympla/events/${symplaId}/`);
    return res.data;
  };

  return {
    getSymplas,
    getSympla,
  };
};
