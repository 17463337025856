import { colors } from './colors';

export const global = {
  styles: {
    global: {
      fontFamily: 'Inter',
      'html, body': {
        h: '100vh',
        bg: colors.body,
        fontFamily: 'Inter',
        color: colors.text.primary,
      },
    },
  },
};
