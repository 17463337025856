import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Menu, MenuButton, MenuList, MenuItem, Text, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function DropdownComponent({ data }: any) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <MenuButton as="div" className="flex h-auto items-center gap-2  py-2">
        <div className="flex h-fit max-w-full items-center justify-center rounded-full border-2 border-custom p-2">
          <Text className="text-sm" color="#718EBF">
            Mais detalhes
          </Text>
          {isOpen ? (
            <MdKeyboardArrowUp color="#718EBF" size="20px" />
          ) : (
            <MdKeyboardArrowDown color="#718EBF" size="20px" />
          )}
        </div>
      </MenuButton>
      <MenuList
        className="-mt-3 max-w-full lg:-mt-2"
        bg="white"
        border="2px solid #718EBF"
        borderRadius="lg"
        boxShadow="lg"
        p="2"
        minW="150px"
      >
        <MenuItem
          className="hover:bg-gray-100"
          onClick={() => handleNavigation(`/breaks/${data.external_id}`)}
        >
          Ver quebras
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default DropdownComponent;
