import React, { useEffect, useState } from 'react';
import { IoCloseCircleOutline, IoWarningOutline, IoCalculatorOutline } from 'react-icons/io5';
import { Spinner, Text } from '@chakra-ui/react';
import { CiCircleCheck } from 'react-icons/ci';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';
import { PageBox } from '../../../components/pageBox';
import { ReturnButton } from '../../../components/buttons/ReturnButton';
import { InputForm } from '../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../components/formBox';
import SelectInputForm from '../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination } from '../../../types/shared';
import { ICompany, useCompanies } from '../../../hooks/useCompanies';

export function modulesHomeTaxPlanningPage() {
  const { getCompanies, getQuebras, getQuebrasTotais } = useCompanies();

  const [filters, setFilters] = useState({
    page: '1',
    size: '10',
    search: '',
    companyId: '',
  });

  const filtersFormMethods = useForm<{ search: string; companyId: any }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.search,
    },
  });

  const debounced = useDebounceCallback(values => {
    setFilters(prevFilters => ({
      ...prevFilters,
      search: values.search,
      companyId: values.companies?.external_id || '',
      page: '1',
    }));
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const queryKey = ['getQuebras', filters];

  const queryKeyQuebrasTotatais = ['getQuebrasTotais', { page: 1, size: 30, search: '' }];

  const { data: dataCompanies, isFetching: isFetchingCompany } = useQuery<IData<ICompany[]>>({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataCompaniesQuebras, isFetching: isFetchingCompanyQuebras } = useQuery<
    IData<ICompany[]>
  >({
    queryKey,
    queryFn: getQuebras,
  });

  const { data: quebrasTotais, isFetching: isFetchingQuebrasTotais } = useQuery<any>({
    queryKey: queryKeyQuebrasTotatais,
    queryFn: getQuebrasTotais,
  });

  const { items: Quebras } = dataCompaniesQuebras?.data ?? { items: [], ...({} as IPagination) };

  const { items: companies } = dataCompanies?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar para o início" />
        <div className="flex flex-col items-center gap-3 p-5 lg:flex-row">
          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#C5B2D4] p-3">
              <IoCalculatorOutline color="#47305A" className="size-12" />
            </div>
          </div>
          <div className="flex w-full flex-col gap-1">
            <Text fontWeight="bold" fontSize="16px">
              PLANEJAMENTO TRIBUTÁRIO
            </Text>
            <Text fontSize="14px" />
          </div>
        </div>

        <div className="mb-5 grid grid-cols-1 gap-3 lg:grid-cols-4">
          {isFetchingQuebrasTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <div className="flex items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#DCFAF8] p-3">
                  <CiCircleCheck color="#16DBCC" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Sem quebra de sequência
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.sem_quebras}
                  </Text>
                </div>
              </div>
              <div className="flex items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFE0EB] p-3">
                  <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Com quebra de sequência
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.quebras}
                  </Text>
                </div>
              </div>
              <div className="flex items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFF5D9] p-3">
                  <IoWarningOutline color="#FFBB38" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Aviso
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.alertas}
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
        <FormBox>
          <div className="flex flex-col gap-4  p-4">
            {isFetchingCompanyQuebras ? (
              <div className="flex flex-col items-center gap-4">
                <Spinner size="lg" color="primary" />
                <span className="text-xl font-semibold">Carregando...</span>
              </div>
            ) : (
              <>
                <div className="flex justify-between gap-2">
                  <FormProvider {...filtersFormMethods}>
                    <form className="flex gap-4">
                      <InputForm
                        name="search"
                        placeholder="Buscar"
                        boxShadow="0px 2px 4px #1E293B0D"
                        controlMb={0}
                      />
                      <SelectInputForm<ICompany>
                        name="companies"
                        placeholder="Selecionar Empresa"
                        closeMenuOnSelect={false}
                        options={companies}
                        getOptionLabel={item => (item as ICompany).nome}
                        getOptionValue={item => String((item as ICompany).external_id)}
                        queryKeyName="CompaniesSelect"
                        getOptions={getCompanies}
                        isLoading={isFetchingCompany}
                      />
                    </form>
                  </FormProvider>
                </div>
                {filters.search && Quebras?.length === 0 && !isFetchingCompanyQuebras ? (
                  <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
                ) : (
                  <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
                    {Quebras?.map(item => (
                      <div className="flex gap-4 rounded-lg border border-border bg-body p-4 shadow-lg lg:flex-col">
                        <div className="flex flex-col justify-between lg:flex-row">
                          <div className="flex flex-col gap-2">
                            <span className="text-base font-bold">{item?.nome}</span>
                            <span className="text-sm  text-[#718EBF]">{item?.razao_social}</span>
                          </div>
                          <div className="flex flex-col gap-2 pt-3 lg:flex-row lg:pt-0">
                            <div className="flex gap-2">
                              <div className="flex items-center gap-2 ">
                                <CiCircleCheck color="#16DBCC" className="size-7" />
                                <Text>{item?.sem_quebras}</Text>
                              </div>
                              <div className="flex items-center gap-2 ">
                                <IoWarningOutline color="#FFBB38" className="size-7" />
                                <Text>10</Text>
                              </div>
                              <div className="flex items-center gap-2 ">
                                <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                                <Text>{item?.quebras}</Text>
                              </div>
                            </div>

                            <div className="flex items-center gap-2 ">
                              <div className="flex h-7 w-36 items-center justify-center  rounded-full border-2 border-custom">
                                <Text className="text-sm" color="#718EBF">
                                  Mais detalhes
                                </Text>
                                <MdKeyboardArrowDown color="#718EBF" size="20px" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </FormBox>
      </div>
    </PageBox>
  );
}
