import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';

export interface IUser {
  id?: number;
  nome: string;
  email: string;
  grupo_id: number;
  empresa_id: number;
  ativo: boolean;
  password: string;
  is_sympla: boolean;
}

interface IUserFilter {
  userId: string;
}

export const useUsers = () => {
  const getUsers = async (key: QueryFunctionContext): Promise<IData<IUser[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<IUser[]>>('/api/v1/usuarios/', {
      params: filters,
    });
    return res.data;
  };

  const getUser = async (key: QueryFunctionContext): Promise<IDataNoPagination<IUser>> => {
    const { userId } = key.queryKey[1] as IUserFilter;
    const res = await api.get<IDataNoPagination<IUser>>(`/api/v1/usuarios/${userId}/`);
    return res.data;
  };

  const createUser = async (data: IUser): Promise<IData<IUser>> => {
    const res = await api.post<IData<IUser>>('/api/v1/usuarios/', data);
    return res.data;
  };

  const updateUser = async (data: IUser): Promise<IData<IUser>> => {
    const res = await api.put<IData<IUser>>(`/api/v1/usuarios/${data.id}/`, data);
    return res.data;
  };

  const deleteUser = async (userId: string) => {
    const res = await api.delete(`/api/v1/usuarios/${userId}/`);
    return res.data;
  };

  return {
    getUsers,
    getUser,

    createUser,
    updateUser,
    deleteUser,
  };
};
