import React from 'react';
import { PageBox } from '../../../components/pageBox';
import { ReturnButton } from '../../../components/buttons/ReturnButton';
import { PageHeader } from '../../../components/PageHeader';
import GroupsForm from '../../../components/forms/GroupsForm';

export default function GroupsCreatePage() {
  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar para Grupos de Usuários" />
        <PageHeader title="Criar novo Grupo de Usuários" />
        <GroupsForm />
      </div>
    </PageBox>
  );
}
