import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IDataNoPagination } from '../types/shared';

export interface IDashboard {
  data_emissao: string;
  nfe: number;
  nfce: number;
  nfse: number;
}

export const useDashboard = () => {
  const getDashboard = async (
    key: QueryFunctionContext,
  ): Promise<IDataNoPagination<IDashboard>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IDataNoPagination<IDashboard>>('/api/v1/dashboard/', {
      params: filters,
    });
    return res.data;
  };

  return {
    getDashboard,
  };
};
