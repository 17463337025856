/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Flex,
  Icon,
  FlexProps,
  ScaleFade,
  Text,
  useColorModeValue,
  Box,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Dot } from 'lucide-react';
import { IMenuIcon } from '../../routes/private.routes';

interface NavItemProps extends FlexProps {
  icon: IMenuIcon;
  parentHref: string;
  parentName: string;
  childrenMenus: any;
  openMenu: boolean;
}

export function NavItemMenu({
  icon,
  parentHref,
  parentName,
  childrenMenus,
  openMenu,
  ...rest
}: NavItemProps) {
  const { pathname } = useLocation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      bg={pathname === parentHref || pathname.includes(`${parentHref}/`) ? 'subMenuBg' : ''}
      borderLeft={pathname === parentHref || pathname.includes(`${parentHref}/`) ? '5px solid' : ''}
      borderLeftColor="primary"
    >
      <Flex
        transition="0.2s"
        w="full"
        cursor="pointer"
        align="center"
        _hover={{ bg: 'gray.100' }}
        onClick={onToggle}
        {...rest}
      >
        <Flex
          w={openMenu ? '80%' : '95%'}
          gap="8px"
          cursor="pointer"
          p="8px 0 8px 15px"
          align="center"
          my="4px"
        >
          <Icon
            w="25px"
            h="25px"
            fontWeight="bold"
            as={icon}
            color={useColorModeValue('text.light.primary', 'text.dark.primary')}
          />
          <ScaleFade in={openMenu} initialScale={0.5}>
            <Text
              minW="95px"
              color={useColorModeValue('text.light.primary', 'text.dark.primary')}
              fontFamily="heading"
              fontWeight="500"
            >
              {parentName}
            </Text>
          </ScaleFade>
        </Flex>
        <Icon w="20px" h="20px" as={isOpen ? ChevronUpIcon : ChevronDownIcon} />
      </Flex>
      <Collapse in={isOpen && openMenu} animateOpacity>
        {childrenMenus.map(submenu => {
          const selected = pathname === submenu.path || pathname.includes(`${submenu.path}/`);
          return (
            <Link to={submenu.path}>
              <Flex
                key={submenu.name}
                w="full"
                gap="8px"
                p={selected ? '8px 0 8px 15px' : '8px 0 8px 48px'}
                my="4px"
                role="group"
                align="center"
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}
              >
                {(pathname === submenu.path || pathname.includes(`${submenu.path}/`)) && (
                  <Icon w="25px" h="20px" as={Dot} color={selected ? 'primary' : 'text.primary'} />
                )}
                <Text color={selected ? 'primary' : 'text.primary'} fontWeight="500">
                  {submenu.name}
                </Text>
              </Flex>
            </Link>
          );
        })}
      </Collapse>
    </Box>
  );
}
