/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { PencilSimpleLine, Trash, User } from 'phosphor-react';
import { PageHeader } from '../../components/PageHeader';
import { IUser, useUsers } from '../../hooks/useUsers';
import { IData, IPagination } from '../../types/shared';
import { DataTable } from '../../components/datatable';
import { TableBox } from '../../components/tableBox';
import { InputForm } from '../../components/inputs/forms/InputForm';
import { EmptyData } from '../../components/emptyData';
import { colors } from '../../theme/colors';
import { ActionsButton } from '../../components/buttons/ActionsButton';
import { useDialog } from '../../providers/dialog';
import { getCellStatus } from '../../utils/FormatFunctions';

export function UsersPage() {
  const showDialog = useDialog();
  const { getUsers, deleteUser } = useUsers();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationFn: deleteUser,
  });

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
    }),
  );

  const filtersFormMethods = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values => setFilters(oldState => ({ ...oldState, ...values, page: 1 })),
    500,
  );

  const queryKey = ['users', Object.fromEntries([...Array.from(filters)])];
  const { data, isFetching } = useQuery<IData<IUser[]>>({
    queryKey,
    queryFn: getUsers,
  });

  const { items: users, ...pagination } = data?.data ?? { items: [], ...({} as IPagination) };

  const columns: ColumnDef<IUser>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'images',
      header: 'Imagem',
    },
    {
      accessorKey: 'nome',
      header: 'Nome',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'grupo.nome',
      header: 'Grupo',
    },
    {
      accessorKey: 'is_sympla',
      header: 'Sympla',
      cell: ({ row }) => getCellStatus(row.original.is_sympla),
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const user = row.original;

        return (
          <ActionsButton aria-label="actions">
            <Link to={`${user.id}/editar`}>
              <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                <PencilSimpleLine size={20} weight="bold" />
                <Text>Editar</Text>
              </Button>
            </Link>

            <Button
              variant="unstyled"
              p="6px"
              display="flex"
              alignItems="center"
              gap={2}
              onClick={() =>
                showDialog({
                  status: 'warning',
                  title: 'Tem certeza que deseja remover este usuário?',
                  description: 'Essa ação não pode ser desfeita.',
                  // cancelLabel: 'Cancelar',
                  async onConfirm() {
                    await mutateAsync(String(user?.id));
                    setFilters(oldState => ({ ...oldState, page: 1 }));
                  },
                })
              }
            >
              <Trash size={20} weight="bold" />
              <Text>Remover</Text>
            </Button>
          </ActionsButton>
        );
      },
    },
  ];

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  return (
    <Box>
      <PageHeader
        title="Usuários"
        subtitle="Dê acesso à sua loja, gerencie os usuários ativos e vincule-os em grupos de trabalho."
      />

      {!filters.get('search') && users.length === 0 && !isFetching ? (
        <EmptyData
          title="Não existem usuários"
          subtitle="Cadastre os usuários"
          buttonLabel="Adicionar novo usuário"
          onClick={() => navigate('/configuracoes/usuarios/criar')}
          icon={<User size={90} color={colors.primary} />}
        />
      ) : (
        <TableBox>
          <div className="flex justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <form className="flex">
                <InputForm
                  name="search"
                  placeholder="Buscar"
                  boxShadow="0px 2px 4px #1E293B0D"
                  controlMb={0}
                />
              </form>
            </FormProvider>

            <Link to="criar">
              <Button>Novo usuário</Button>
            </Link>
          </div>

          <div className="border border-b-divider" />

          <DataTable
            columns={columns}
            data={users}
            pagination={pagination}
            emptyMsg={
              filters.get('search')
                ? 'Nenhum usuário encontrado.'
                : 'Nenhum usuário cadastrado ainda.'
            }
            loadingMsg="Carregando usuários..."
            isFetching={isFetching}
          />
        </TableBox>
      )}
    </Box>
  );
}
