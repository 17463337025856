import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Flex, Icon, FlexProps, ScaleFade, Text, Spacer } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { IconProps } from 'phosphor-react';

interface NavItemProps extends FlexProps {
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  href: string;
  onClick?: () => void;
  children: string;
  openMenu: boolean;
}

export function NavItem({ icon, href, onClick, children, openMenu, ...rest }: NavItemProps) {
  const { pathname } = useLocation();

  return (
    <Flex
      transition="0.2s"
      w="full"
      cursor="pointer"
      align="center"
      bg={pathname === href || pathname.includes(`${href}/`) ? 'subMenuBg' : ''}
      borderLeft={pathname === href || pathname.includes(`${href}/`) ? '5px solid' : ''}
      borderLeftColor="primary"
      _hover={{ bg: 'gray.100' }}
      onClick={() => (onClick ? onClick() : undefined)}
      {...rest}
    >
      <Flex
        w={openMenu ? '80%' : '95%'}
        gap="8px"
        p="8px 0 8px 15px"
        align="center"
        justify="center"
        my="4px"
      >
        {icon && (
          <Icon
            w="25px"
            h="25px"
            fontWeight="bold"
            as={icon}
            color={pathname === href || pathname.includes(`${href}/`) ? 'primary' : 'text.primary'}
          />
        )}
        <ScaleFade in={openMenu} initialScale={0.5}>
          {openMenu && (
            <Text
              color={
                pathname === href || pathname.includes(`${href}/`) ? 'primary' : 'text.primary'
              }
              fontFamily="heading"
              fontWeight="500"
            >
              {children}
            </Text>
          )}
        </ScaleFade>
        <Spacer />
      </Flex>
    </Flex>
  );
}
