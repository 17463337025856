import React from 'react';
import { Heading, Text, Button } from '@chakra-ui/react';
import { HiMiniComputerDesktop } from 'react-icons/hi2';
import { DeleteIcon } from '@chakra-ui/icons';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ISessions, useSessions } from '../../hooks/useSessions';

interface FormHeaderProps {
  data: ISessions;
  refetch: () => void;
}

export const SessionsCard = ({ data, refetch }: FormHeaderProps) => {
  const { deleteSession } = useSessions();

  const { mutateAsync } = useMutation({
    mutationFn: deleteSession,
  });

  const onSubmit = async () => {
    try {
      await mutateAsync(String(data.id));
      await refetch();
      toast.success(`Ip deletado com sucesso!`, {
        position: 'top-right',
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <div className="grid grid-cols-2 items-center justify-between gap-7 md:grid-cols-3 ">
      <div className="col-span-2 flex items-center justify-center gap-7 md:justify-start">
        <div className="flex items-center justify-center rounded-full bg-gray-200 p-6 ">
          <HiMiniComputerDesktop className="text-2xl text-black" />
        </div>
        <div className="flex flex-col">
          <Heading fontSize="18px" fontWeight="500">
            ENDEREÇO IP
          </Heading>
          <Text color="#71717A">41.232.214.520</Text>
        </div>
      </div>
      <div className="col-span-2 md:col-span-1">
        <Button
          variant="ghost"
          color="#EB5757"
          w="100%"
          leftIcon={<DeleteIcon color="#EB5757" />}
          onClick={() => onSubmit()}
        >
          Remover
        </Button>
      </div>
    </div>
  );
};
