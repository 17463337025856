import React from 'react';
import {
  Center,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
} from '@chakra-ui/react';
import { CheckCircle, Info, Warning, X, XCircle } from 'phosphor-react';

interface Props {
  isOpen: boolean;
  status: 'info' | 'success' | 'warning' | 'error';
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const DialogModal = ({
  isOpen,
  status,
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmar',
  cancelLabel,
}: Props) => {
  const statusProps = {
    info: {
      color: 'blue',
      icon: Info,
    },
    success: {
      color: 'green',
      icon: CheckCircle,
    },
    warning: {
      color: '#DBA700',
      icon: Warning,
    },
    error: {
      color: 'red',
      icon: XCircle,
    },
  };

  return (
    <Modal isCentered closeOnEsc closeOnOverlayClick isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent
        w="100%"
        borderRadius="20px"
        boxShadow="0px 2px 5px #0000000D"
        justifyContent="space-between"
      >
        <ModalHeader
          display="flex"
          borderTopRadius="20px"
          p="0px"
          background={statusProps[status]?.color}
          justifyContent="end"
        >
          <IconButton
            onClick={onCancel}
            color="white"
            icon={<X />}
            fontSize="20px"
            mr={2}
            variant="ghost"
            _hover={{ opacity: 0.5 }}
            aria-label=""
          />
        </ModalHeader>

        <ModalBody mb={5}>
          <Center my={5}>
            {status && (
              <Icon
                as={statusProps[status]?.icon}
                color={statusProps[status]?.color}
                mr="5px"
                fontSize="100px"
              />
            )}
          </Center>
          <Text fontWeight="bold" textAlign="center" fontSize="24px">
            {title}
          </Text>

          {description && (
            <Text textAlign="center" fontSize="16px">
              {description}
            </Text>
          )}
        </ModalBody>

        <ModalFooter justifyContent="space-between" className="flex items-center gap-2">
          {cancelLabel && (
            <Button variant="ghost" onClick={onCancel}>
              {cancelLabel}
            </Button>
          )}
          <Button onClick={onConfirm} justifySelf="end">
            {confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogModal;
