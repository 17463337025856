/* eslint-disable react/no-children-prop */
import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type FormButtonProps = ButtonProps;

export const FormButton = ({ children, ...rest }: FormButtonProps) => {
  return (
    <Button mt={20} h={46} {...rest}>
      {children}
    </Button>
  );
};
