export const colors = {
  body: '#FFFFFF',
  background: '#F5F6F6',
  primary: '#1B88F5',
  'primary.500': '#FF7C40',
  secondary: '#008CCC',
  tertiary: '#A6A6A6',
  subMenuBg: '#DFF3FF',
  divider: '#E6E7E7',
  inputBorder: '#CBD5E1',
  border: '#E4E4E7',
  button: {
    green: '#27AE60',
  },
  grayBorder: '#AFB1B1',
  text: {
    primary: '#0F172A',
    secondary: '#71717A',
    tertiary: '#008CCC',
    quaternary: '#718EBF',
    placeholder: '#94A3B8',
  },
};
