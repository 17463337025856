import React from 'react';
import { Box, Stack, UseDisclosureReturn } from '@chakra-ui/react';
import { List } from 'phosphor-react';
import { NavItem } from '../navItem';
import Options from './Options';

interface SidebarProps {
  isOpen: boolean;
  onToggle: UseDisclosureReturn['onToggle'];
}

const Sidebar = ({ isOpen, onToggle }: SidebarProps) => {
  return (
    <Stack
      position="fixed"
      transition="0.5s ease"
      bg="white"
      borderRight="1px solid"
      borderRightColor="border"
      w={isOpen ? '216px' : '64px'}
      h="100%"
      overflowY="auto"
      overflowX="hidden"
      justifyContent="space-between"
      userSelect="none"
    >
      <Box>
        <div className="hidden md:block">
          <NavItem href="Menu" onClick={onToggle} icon={List} openMenu={isOpen}>
            Menu
          </NavItem>
        </div>

        <Options isOpen={isOpen} />
      </Box>
    </Stack>
  );
};

export default Sidebar;
