/* eslint-disable no-unused-vars */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';
import { InputForm } from '../../../inputs/forms/InputForm';
import { FormButton } from '../../../buttons/FormButton';
import { ISendCode, useRecover } from '../../../../hooks/useRecover';

const schema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
});

interface IForm {
  email: string;
}

interface EmailStepProps {
  nextStep: () => void;
  setUserEmail: (email: string) => void;
}

export default function EmailStepForm({ nextStep, setUserEmail }: EmailStepProps) {
  const { SendCode } = useRecover();

  const { mutateAsync } = useMutation({
    mutationFn: SendCode,
  });
  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      setUserEmail(values.email);
      await mutateAsync({
        email: values.email,
      } as unknown as ISendCode);
      nextStep();
      toast.success('Código enviado com sucesso!', { position: 'top-center' });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
        { position: 'top-center' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <InputForm name="email" label="E-mail" placeholder="Seu e-mail de login" />

        <FormButton mt={4} isLoading={formMethods.formState.isSubmitting} type="submit" w="100%">
          Entrar
        </FormButton>
      </FormProvider>
    </form>
  );
}
