import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';
import { InputForm } from '../../../inputs/forms/InputForm';
import { FormButton } from '../../../buttons/FormButton';
import { IUpdatePassword, useRecover } from '../../../../hooks/useRecover';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório.')
    .min(6, 'Senha deve conter pelo menos 6 caracteres.')
    .max(20, 'Senha deve conter no máximo 20 caracteres.'),
  confirmPass: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('password')], 'As senhas não são iguais'),
});

interface IForm {
  password: string;
  confirmPass: string;
}
interface NewPassStepProps {
  nextStep: () => void;
  userEmail: string;
  approvalHash: string;
}

export default function NewPassStepForm({ nextStep, userEmail, approvalHash }: NewPassStepProps) {
  const { updatePassword } = useRecover();

  const { mutateAsync } = useMutation({
    mutationFn: updatePassword,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPass: '',
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync({
        email: userEmail,
        approval_hash: approvalHash,
        new_password: values.password,
      } as unknown as IUpdatePassword);
      nextStep();
      toast.success('Senha alterada com sucesso com sucesso!', { position: 'top-center' });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
        { position: 'top-center' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <InputForm name="password" label="Crie nova senha" placeholder="Crie nova senha" />

        <InputForm
          name="confirmPass"
          label="Confirme a nova senha"
          placeholder="Confirme a nova senha"
        />

        <FormButton mt={4} isLoading={formMethods.formState.isSubmitting} type="submit" w="100%">
          Nova Senha
        </FormButton>
      </FormProvider>
    </form>
  );
}
