import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { List } from 'phosphor-react';
import React from 'react';
import Options from './Options';

export function ResponsiveMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  return (
    <>
      <IconButton aria-label="menu" icon={<List size={30} />} onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent className="overflow-auto border-none bg-primary p-0">
          <DrawerCloseButton />
          <DrawerHeader>
            <div />
          </DrawerHeader>

          <DrawerBody
            w="full"
            css={{
              '-webkit-padding-start': '0px',
              '-webkit-padding-end': '0px',
            }}
          >
            <Options isOpen handleClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
