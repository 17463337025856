import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { ICity, IData, IDataNoPagination } from '../types/shared';

export interface IClient {
  id?: number;
  nome: string;
  cidade: ICity;
  total_faturas: number;
  total_ucs: number;
  ativo?: boolean;
}

interface IClientFilter {
  clientId: string;
}

export const useClients = () => {
  const getClients = async (key: QueryFunctionContext): Promise<IData<IClient[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<IClient[]>>('/api/v1/clientes/', {
      params: filters,
    });
    return res.data;
  };

  const getClient = async (key: QueryFunctionContext): Promise<IDataNoPagination<IClient>> => {
    const { clientId } = key.queryKey[1] as IClientFilter;
    const res = await api.get<IDataNoPagination<IClient>>(`/api/v1/clientes/${clientId}/`);
    return res.data;
  };

  const createClient = async (data: IClient): Promise<IData<IClient>> => {
    const res = await api.post<IData<IClient>>('/api/v1/clientes/', data);
    return res.data;
  };

  const updateClient = async (data: IClient): Promise<IData<IClient>> => {
    const res = await api.put<IData<IClient>>(`/api/v1/clientes/${data.id}/`, data);
    return res.data;
  };

  const deleteClient = async (clientId: string) => {
    const res = await api.delete(`/api/v1/clientes/${clientId}/`);
    return res.data;
  };

  return {
    getClients,
    getClient,

    createClient,
    updateClient,
    deleteClient,
  };
};
