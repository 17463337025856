import React, { useState } from 'react';
import { Container, Heading, Text, Center, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import EmailStepForm from '../../../components/forms/Recover/EmailStepForm';
import PinStepForm from '../../../components/forms/Recover/PinStepForm';
import NewPassStepForm from '../../../components/forms/Recover/NewPassStepForm';
import { FormButton } from '../../../components/buttons/FormButton';

export function RecoverPage() {
  const [step, setStep] = useState<number>(1);
  const [userEmail, setUserEmail] = useState<string>('');
  const [approvalHash, setApprovalHash] = useState<string>('');

  const navigate = useNavigate();

  const nextStep = () => {
    setStep(OldStep => OldStep + 1);
  };

  return (
    <div className="flex h-screen bg-body">
      <div className="hidden h-full w-full bg-login_bg bg-cover bg-left sm:block" />
      <Center w="60%" backgroundColor="body">
        <Container maxW="500px">
          <div className="flex flex-col items-center gap-3">
            <Heading color="text.tertiary" fontSize={24} textAlign="center">
              {step === 1 && 'Recuperar senha'}
              {step === 2 && 'Insira código'}
              {step === 3 && 'Nova Senha'}
              {step === 4 && 'Nova senha criada'}
            </Heading>
            <Text color="text.secondary" mb="14px" textAlign="center">
              {step === 1 && 'Digite seu e-mail'}
              {step === 2 && 'Verifique seu e-mail para pegar seu código de recuperação de senha'}
              {step === 3 && 'Digite sua nova senha'}
              {step === 4 &&
                'Sua nova senha foi criada com sucesso. Agora, você precisa fazer login novamente.'}
            </Text>
          </div>
          {step === 1 && <EmailStepForm nextStep={nextStep} setUserEmail={setUserEmail} />}
          {step === 2 && (
            <PinStepForm
              nextStep={nextStep}
              userEmail={userEmail}
              setApprovalHash={setApprovalHash}
            />
          )}
          {step === 3 && (
            <NewPassStepForm
              nextStep={nextStep}
              userEmail={userEmail}
              approvalHash={approvalHash}
            />
          )}
          {step === 4 && (
            <FormButton mt={4} onClick={() => navigate('/Login')} type="submit" w="100%">
              Voltar ao login
            </FormButton>
          )}

          {step !== 4 && (
            <div className="mt-8 flex justify-center">
              <Text color="text.secondary" mb="14px">
                Voltar ao{' '}
                <Button variant="link" color="text.tertiary" onClick={() => navigate('/Login')}>
                  Login
                </Button>
              </Text>
            </div>
          )}
        </Container>
      </Center>
    </div>
  );
}
