import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Text } from '@chakra-ui/react';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { SessionsCard } from '../../sessionsCard';
import { ISessions, useSessions } from '../../../hooks/useSessions';
import { IData, IPagination } from '../../../types/shared';

const schema = yup.object().shape({
  Teste: yup.string(),
});

interface IForm {
  Teste?: string;
}

export default function SessionsForm() {
  const [filters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
    }),
  );
  const { getSessions } = useSessions();
  const queryKey = ['clients', Object.fromEntries([...Array.from(filters)])];

  const { data, refetch } = useQuery<IData<ISessions[]>>({
    queryKey,
    queryFn: getSessions,
  });
  const { items: clients } = data?.data ?? { items: [], ...({} as IPagination) };

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      Teste: '',
    },
  });

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onSubmit = async (values: IForm) => {
    // try {
    // if (values.id) {
    //     await updateUser(values);
    //   } else {
    //     await createUser(values);
    //   }
    //   toast.success('Login realizado com sucesso!', { position: 'top-center' });
    // } catch (error: any) {
    //   toast.error(
    //     error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
    //     { position: 'top-center' },
    //   );
    // }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader
          title="Sessões"
          subtitle="Aqui estão todas as suas sessões de login. Você pode remover as sessões que não estiver mais utilizando ou sessões não legítimas."
        />

        <FormBox>
          <FormProvider {...formMethods}>
            <Text color="#0F172A" fontWeight="500" size="14px" lineHeight="20px">
              Sessões
            </Text>
            <div className="flex flex-col gap-10">
              {clients?.map(item => <SessionsCard data={item} refetch={refetch} />)}
            </div>
          </FormProvider>
        </FormBox>
      </div>
    </form>
  );
}
