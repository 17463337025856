import React from 'react';
import SessionsForm from '../../components/forms/SessionsForm';
import { PageBox } from '../../components/pageBox';
import { PageHeader } from '../../components/PageHeader';

export default function SessionsPage() {
  return (
    <div className="mx-auto w-full lg:max-w-[1200px]">
      <PageBox>
        <div className="flex flex-col gap-2">
          <PageHeader title="Suas sessões de login" />
          <SessionsForm />
        </div>
      </PageBox>
    </div>
  );
}
