import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  Switch as ChakraSwitch,
  Text,
  SwitchProps as IProps,
  Flex,
} from '@chakra-ui/react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

interface SwitchProps extends IProps {
  name: string;
  isRequired?: boolean;
  ativo?: boolean;
  // eslint-disable-next-line no-unused-vars
  onToggleChange?: (isChecked: boolean) => void;
}

export const SwitchHookForm = ({
  name,
  isRequired,
  onToggleChange,
  ativo,
  ...rest
}: SwitchProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={5} w={100}>
      <Flex align="center" mb={2}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ChakraSwitch
              id={name}
              isChecked={value}
              onChange={e => {
                onChange(e.target.checked);
                onToggleChange?.(e.target.checked);
              }}
              {...rest}
            />
          )}
        />
        {ativo && <Text>Ativo?</Text>}
      </Flex>
      {errors && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
