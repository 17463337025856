import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@chakra-ui/react';
import { IUser, useUsers } from '../../../hooks/useUsers';
import { PageBox } from '../../../components/pageBox';
import { PageHeader } from '../../../components/PageHeader';
import { ReturnButton } from '../../../components/buttons/ReturnButton';
import { IDataNoPagination } from '../../../types/shared';
import UsersForm from '../../../components/forms/UsersForm';

export default function UsersEditPage() {
  const { getUser } = useUsers();
  const { itemId } = useParams();

  const {
    data,
    isFetching: loadingData,
    isError,
  } = useQuery<IDataNoPagination<IUser>>({
    queryFn: getUser,
    queryKey: ['getUser', { userId: itemId }],
  });

  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <ReturnButton label="Voltar para Usuários" />
        <PageHeader title="Novo Usuário" />
        {loadingData ? (
          <div className="flex flex-col items-center gap-4">
            <Spinner size="lg" color="primary" />
            <span className="text-xl font-semibold">Carregando...</span>
          </div>
        ) : (
          <UsersForm defaultValues={isError ? undefined : (data as any)?.data} />
        )}
      </div>
    </PageBox>
  );
}
