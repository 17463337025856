import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'sonner';
import { Button } from '@chakra-ui/react';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { CurrencyInputForm } from '../../inputs/forms/CurrencyInputForm';
import { TextAreaInputForm } from '../../inputs/forms/TextAreaInputForm';
// import { useUsers } from '../../../hooks/useUsers';

const schema = yup.object().shape({
  nome: yup.string().required('Senha obrigatória'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  grupo_id: yup.number().required('Senha obrigatória'),
  password: yup.string().required('Senha obrigatória'),
});

interface IForm {
  nome: string;
  email: string;
  grupo_id: number;
  password: string;
}

export default function CompanyForm() {
  // const navigate = useNavigate();
  // const { createUser, updateUser } = useUsers();

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      email: '',
      grupo_id: undefined,
      password: '',
    },
  });

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onSubmit = async (values: IForm) => {
    // try {
    // if (values.id) {
    //     await updateUser(values);
    //   } else {
    //     await createUser(values);
    //   }
    //   toast.success('Login realizado com sucesso!', { position: 'top-center' });
    // } catch (error: any) {
    //   toast.error(
    //     error?.response?.data?.detail ?? 'Erro interno no sistema, Tente novamente mais tarde.',
    //     { position: 'top-center' },
    //   );
    // }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader
          title="Dados principais"
          subtitle="Configure as principais informações sobre sua empresa."
        />

        <FormBox>
          <FormProvider {...formMethods}>
            <InputForm name="nome" label="CPF/CNPJ" placeholder="CPF/CNPJ" />

            <InputForm name="email" label="Razão Social" placeholder="Razão Social" />

            <InputForm
              name="password"
              label="Nome Comercial (opcional)"
              placeholder="Nome Comercial"
              helper="Esse é o nome que aparecerá no checkout e nos e-mails enviados após as compras. Caso não seja informado, será usada sua Razão Social."
            />

            <InputForm name="email" label="Nome na fatura" placeholder="Nome na fatura" />
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader
          title="Detalhes da empresa"
          subtitle="Informações sobre sua operação, faturamento e contato."
        />

        <FormBox>
          <FormProvider {...formMethods}>
            <CurrencyInputForm name="nome" label="Faturamento médio" placeholder="R$1.000,00" />

            <CurrencyInputForm name="password" label="Ticket médio" placeholder="R$1.000,00" />

            <TextAreaInputForm
              name="email"
              label="Quais produtos você vende?"
              placeholder="Quais produtos você vende?"
            />

            <InputForm name="email" label="Site / Instagram" placeholder="Site / Instagram" />

            <InputForm
              name="email"
              label="Telefone para contato"
              placeholder="Telefone para contato"
              mask="(99) 99999-9999"
            />
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="flex justify-end">
        <Button type="submit">Salvar</Button>
      </div>
    </form>
  );
}
