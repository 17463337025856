// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { fonts } from './fonts';
import { global } from './global';
import { buttonStyle } from './components/button';
import { inputStyle } from './components/input';
import { switchStyle } from './components/switch';
import { textAreaStyle } from './components/text_area';

export const theme = extendTheme({
  ...global,
  colors,
  fonts,
  components: {
    Button: buttonStyle,
    Input: inputStyle,
    Textarea: textAreaStyle,
    Switch: switchStyle,
  },
});
