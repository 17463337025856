import React from 'react';
import { SwitchForm } from '../inputs/forms/SwitchInputForm';

interface FormStatusProps {
  title: string;
  subtitle: string;
  name: string;
}

export const FormStatus = ({ title, subtitle, name }: FormStatusProps) => {
  return (
    <div className="flex items-center justify-between bg-[#DCFAF8] p-3">
      <div className="flex flex-col">
        <span className="text-lg font-semibold">{title}</span>
        <span>{subtitle}</span>
      </div>

      <SwitchForm name={name} />
    </div>
  );
};
