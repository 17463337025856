import { api } from '../services/api';
import { IDataNoPagination } from '../types/shared';

export interface ISendCode {
  email: string;
}
export interface IValidateCode extends ISendCode {
  code: string;
  approval_hash: string;
}

export interface IUpdatePassword extends IValidateCode {
  new_password: string;
}

export const useRecover = () => {
  const SendCode = async (data: ISendCode): Promise<IDataNoPagination<ISendCode>> => {
    const res = await api.post<IDataNoPagination<ISendCode>>('/api/v1/login/recover/', data);
    return res.data;
  };
  const validateCode = async (data: IValidateCode): Promise<IDataNoPagination<IValidateCode>> => {
    const res = await api.post<IDataNoPagination<IValidateCode>>(
      '/api/v1/login/verify-code/',
      data,
    );
    return res.data;
  };

  const updatePassword = async (
    data: IUpdatePassword,
  ): Promise<IDataNoPagination<IUpdatePassword>> => {
    const res = await api.post<IDataNoPagination<IUpdatePassword>>(
      '/api/v1/login/reset-password/',
      data,
    );
    return res.data;
  };

  return {
    SendCode,
    validateCode,
    updatePassword,
  };
};
