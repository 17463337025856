import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';
import { IPermissions } from './usePermissions';

export interface IGroup {
  id?: number;
  nome: string;
  permissoes: IPermissions[];
}

interface IGroupFilter {
  groupId: string;
}

export const useGroups = () => {
  const getGroups = async (key: QueryFunctionContext): Promise<IData<IGroup[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<IGroup[]>>('/api/v1/grupos/', {
      params: filters,
    });
    return res.data;
  };

  const getGroup = async (key: QueryFunctionContext): Promise<IDataNoPagination<IGroup>> => {
    const { groupId } = key.queryKey[1] as IGroupFilter;
    const res = await api.get<IDataNoPagination<IGroup>>(`/api/v1/grupos/${groupId}/`);
    return res.data;
  };

  const createGroup = async (data: IGroup): Promise<IDataNoPagination<IGroup>> => {
    const res = await api.post<IDataNoPagination<IGroup>>('/api/v1/grupos/', data);
    return res.data;
  };

  const updateGroup = async (data: IGroup): Promise<IDataNoPagination<IGroup>> => {
    const res = await api.put<IDataNoPagination<IGroup>>(`/api/v1/grupos/${data.id}/`, data);
    return res.data;
  };

  const addGroupPermissions = async (data: {
    id: number;
    permissoes: { permissao_id: number }[];
  }): Promise<IDataNoPagination<IGroup>> => {
    const res = await api.put<IDataNoPagination<IGroup>>(
      `/api/v1/grupos/${data.id}/permissions/`,
      data.permissoes,
    );
    return res.data;
  };

  const deleteGroup = async (groupId: string) => {
    const res = await api.delete(`/api/v1/grupos/${groupId}/`);
    return res.data;
  };

  return {
    getGroups,
    getGroup,

    createGroup,
    updateGroup,
    addGroupPermissions,
    deleteGroup,
  };
};
