import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  Switch as ChakraSwitch,
  Text,
  SwitchProps as IProps,
  Flex,
} from '@chakra-ui/react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

interface SwitchProps extends IProps {
  name: string;
  isRequired?: boolean;
  ativo?: boolean;
  // eslint-disable-next-line no-unused-vars
  onToggleChange?: (isChecked: boolean) => void;
}

export const SwitchForm = ({ name, isRequired, onToggleChange, ativo, ...rest }: SwitchProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  return (
    <div>
      <FormControl isRequired={isRequired} isInvalid={!!error} mb={5}>
        <Flex align="center" mb={2}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
              <ChakraSwitch
                id={name}
                isChecked={value}
                onChange={e => {
                  onChange(e.target.checked);
                  onToggleChange?.(e.target.checked);
                }}
                {...rest}
              />
            )}
          />
          {ativo && <Text>Ativo?</Text>}
        </Flex>
        {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
      </FormControl>
    </div>
  );
};
