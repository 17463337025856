import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

interface FormHeaderProps {
  title: string;
  subtitle?: string;
}

export const FormHeader = ({ title, subtitle }: FormHeaderProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Heading fontSize="18px" fontWeight="500">
        {title}
      </Heading>

      {subtitle && <Text color="text.primary">{subtitle}</Text>}
    </div>
  );
};
