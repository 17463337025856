import { ComponentStyleConfig } from '@chakra-ui/react';

export const switchStyle: ComponentStyleConfig = {
  baseStyle: {},

  variants: {
    outline: {},
  },

  sizes: {},

  defaultProps: {},
};
