import { api } from '../services/api';
import { IDataNoPagination } from '../types/shared';

export interface IProfile {
  nome: string;
  email: string;
  grupo_id: number;
  empresa_id: number;
  ativo: boolean;
  senha: string;
  nova_senha: string;
}

export const useProfile = () => {
  const updateProfile = async (data: IProfile): Promise<IDataNoPagination<IProfile>> => {
    const res = await api.put<IDataNoPagination<IProfile>>(`/api/v1/usuarios/perfil/`, data);
    return res.data;
  };

  return {
    updateProfile,
  };
};
