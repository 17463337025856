/* eslint-disable import/no-extraneous-dependencies */
import React, { CSSProperties } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

interface TextAreaInputFormProps extends TextareaProps {
  name: string | undefined | any;
  label: string | undefined;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  labelStyles?: CSSProperties;
  infoButton?: JSX.Element;
  isRequired?: boolean;
}

export const TextAreaInputForm = ({
  name,
  label,
  labelStyles,
  isRequired = false,
  ...rest
}: TextAreaInputFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);
  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={5}>
      <FormLabel style={labelStyles} fontWeight="bold" mb={1} mr={3} htmlFor={name}>
        {label}
      </FormLabel>
      <Textarea
        id={name}
        background="body"
        isInvalid={!!error}
        errorBorderColor="red.400"
        minH="150px"
        {...register(name)}
        {...rest}
      />
      {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
