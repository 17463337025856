import axios from 'axios';
import { getAuthStorage, removeAuthStorage, setAuthStorage } from './storage';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },

  async error => {
    if (error.response.status === 401) {
      if (
        error.config.url.includes('login') === true ||
        error.config.url.includes('recover') === true
      ) {
        return Promise.reject(error);
      }
      const token = await refreshToken();

      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${token}`;

      return api(error.config);
    }

    return Promise.reject(error);
  },
);

const refreshToken = async () => {
  try {
    const storageAuth = await getAuthStorage();
    if (storageAuth) {
      api.defaults.headers.common.Authorization = `Bearer ${storageAuth.refresh_token}`;
    }
    const res = await api.post('/api/v1/login/refresh-token/');
    await setAuthStorage(res.data.data);

    const token = res.data.data.access_token;
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return token;
  } catch (error: any) {
    if (
      error.response?.status === 404 &&
      error.response?.data.detail === 'User or Session inactive'
    ) {
      await removeAuthStorage();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
};
