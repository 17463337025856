/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import moment from 'moment';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { PageBox } from '../../../../components/pageBox';

import { IDataNoPagination } from '../../../../types/shared';
import { IDashboard, useDashboard } from '../../../../hooks/useDashboard';

import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';

const initialFilters = {
  date_start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  date_end: moment().format('YYYY-MM-DD'),
};

export default function DashboardPage() {
  const { getDashboard } = useDashboard();
  const [filters, setFilters] = useSearchParams(createSearchParams(initialFilters));
  const queryKey = ['dashboard', Object.fromEntries([...Array.from(filters)])];

  const { data } = useQuery<IDataNoPagination<IDashboard>>({
    queryFn: getDashboard,
    queryKey,
  });
  const dashboard = data?.data;
  const total = dashboard ? dashboard?.nfe + dashboard?.nfce + dashboard?.nfse : 0;

  const getPieData = entry => {
    const PieData =
      total > 0
        ? [
            { name: 'NFE', value: entry.nfe },
            { name: 'NFCE', value: entry.nfce },
            { name: 'NFSE', value: entry.nfse },
          ]
        : [
            { name: 'NFE', value: 0 },
            { name: 'NFCE', value: 0 },
            { name: 'NFSE', value: 0 },
          ];

    if (PieData.every(item => item.value === 0)) {
      PieData[0].value = 0;
    }

    return PieData;
  };

  const formatTooltipValue = value => {
    const numericValue = parseFloat(((value / total) * 100).toFixed(2));
    return `${numericValue.toFixed(2)}%`;
  };

  return (
    <PageBox>
      <ReturnButton label="Voltar para página anterior" previousPage="modulesHome/integration" />
      <HStack justify="flex-end" mt={8} mb={8}>
        <DateToolbarFilter setFilters={setFilters} filters={filters} />
      </HStack>
      <div className="flex flex-col gap-5">
        <div className="grid gap-5 lg:grid-cols-1">
          <div className="flex w-full flex-col gap-4">
            <Text fontSize="18px" fontWeight="bold">
              NOTAS
            </Text>

            {(dashboard && (dashboard?.nfce || 0) > 0) ||
            (dashboard?.nfe || 0) > 0 ||
            (dashboard?.nfse || 0) > 0 ? (
              <div className="flex flex-col items-center">
                {/* <Text fontSize="16px" fontWeight="bold" mb={2}>
                      Data: {entry.data_emissao}
                    </Text> */}
                <div className="flex w-full justify-end rounded-lg bg-white p-5 shadow-lg">
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getPieData(dashboard)}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={100}
                        label
                      >
                        <Cell name="NFE" fill="#8884d8" />
                        <Cell name="NFCE" fill="#82ca9d" />
                        <Cell name="NFSE" fill="#ffc658" />
                      </Pie>
                      <Tooltip formatter={formatTooltipValue} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : (
              <Box className="flex h-[70vh] items-center justify-center">
                <Text fontSize="18px" fontWeight="bold" color="gray.500">
                  Não existe dados suficiente para a data selecionada
                </Text>
              </Box>
            )}
          </div>
        </div>
      </div>
    </PageBox>
  );
}
