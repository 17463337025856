import React, { useEffect } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { Building } from 'lucide-react';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import { PageHeader } from '../../components/PageHeader';
import { IData, IPagination } from '../../types/shared';
import { DataTable } from '../../components/datatable';
import { TableBox } from '../../components/tableBox';
import { EmptyData } from '../../components/emptyData';
import { colors } from '../../theme/colors';
import { InputForm } from '../../components/inputs/forms/InputForm';
import { ActionsButton } from '../../components/buttons/ActionsButton';
import { useDialog } from '../../providers/dialog';
import { IPermissions, usePermissions } from '../../hooks/usePermissions';

export function PermissionsPage() {
  const { getPermissions, deletePermission } = usePermissions();
  const showDialog = useDialog();
  const navigate = useNavigate();
  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
    }),
  );
  const { mutateAsync } = useMutation({
    mutationFn: deletePermission,
  });
  const filtersFormMethods = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(
    values =>
      setFilters(state => {
        state.set('search', values.search);
        return { ...state, page: '1' };
      }),
    500,
  );

  const queryKey = ['permissions', Object.fromEntries([...Array.from(filters)])];
  const { data, isFetching } = useQuery<IData<IPermissions[]>>({
    queryKey,
    queryFn: getPermissions,
  });

  const { items: permissions, ...pagination } = data?.data ?? { items: [], ...({} as IPagination) };

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const columns: ColumnDef<IPermissions>[] = [
    {
      accessorKey: 'nome',
      header: 'Nome',
    },
    {
      accessorKey: 'codigo',
      header: 'Código',
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const uc = row.original;

        return (
          <ActionsButton aria-label="actions">
            <Link to={`${uc.id}/editar`}>
              <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                <PencilSimpleLine size={20} weight="bold" />
                <Text>Editar</Text>
              </Button>
            </Link>

            <Button
              variant="unstyled"
              p="6px"
              display="flex"
              alignItems="center"
              gap={2}
              onClick={() =>
                showDialog({
                  status: 'warning',
                  title: 'Tem certeza que deseja remover esta permissão?',
                  description: 'Essa ação não pode ser desfeita.',
                  cancelLabel: 'Cancelar',
                  async onConfirm() {
                    await mutateAsync(String(uc?.id));
                    setFilters(oldState => ({ ...oldState, page: 1 }));
                  },
                })
              }
            >
              <Trash size={20} weight="bold" />
              <Text>Remover</Text>
            </Button>
          </ActionsButton>
        );
      },
    },
  ];

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  return (
    <Box>
      <PageHeader title="Permissões" subtitle="Cadastre as Permissões aqui." />

      {!filters.get('search') && permissions.length === 0 && !isFetching ? (
        <EmptyData
          title="Não existem Permissões"
          subtitle="Cadastre suas Permissões"
          buttonLabel="Adicionar nova Permissão"
          onClick={() => navigate('/configuracoes/permissoes/criar')}
          icon={<Building size={90} color={colors.primary} />}
        />
      ) : (
        <TableBox>
          <div className="flex justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <form className="flex flex-col gap-2 lg:flex-row">
                <InputForm
                  name="search"
                  placeholder="Buscar"
                  boxShadow="0px 2px 4px #1E293B0D"
                  controlMb={0}
                />
              </form>
            </FormProvider>

            <Link to="criar">
              <Button>Nova permissão</Button>
            </Link>
          </div>

          <div className="border border-b-divider" />

          <DataTable<IPermissions>
            columns={columns}
            data={permissions}
            pagination={pagination}
            emptyMsg="Nenhuma Permissão encontrada."
            loadingMsg="Carregando Permissões..."
            isFetching={isFetching}
          />
        </TableBox>
      )}
    </Box>
  );
}
