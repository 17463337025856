/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@chakra-ui/react';
import moment from 'moment';
import { PageBox } from '../../../components/pageBox';
import { ReturnButton } from '../../../components/buttons/ReturnButton';
import { FormBox } from '../../../components/formBox';
import { IDataNoPagination } from '../../../types/shared';
import { ISympla, useSympla } from '../../../hooks/useSympla';

export function ViewSymplaPage() {
  const { itemId } = useParams();
  const { getSympla } = useSympla();
  const [filters, setFilters] = useState({
    page: '1',
    size: '10',
    search: '',
  });

  const {
    data: SymplaData,
    // error: SymplaError,
    isLoading: loadingSympla,
  } = useQuery<IDataNoPagination<ISympla>>({
    queryFn: getSympla,
    queryKey: ['symplaId', { symplaId: itemId }],
  });

  const filtersFormMethods = useForm<{ search: string; companyId: any }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.search,
    },
  });

  const debounced = useDebounceCallback(values => {
    setFilters(prevFilters => ({
      ...prevFilters,
      search: values.search,
      companyId: values.companies?.external_id || '',
      page: '1',
    }));
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  return (
    <PageBox>
      <div className="flex flex-col gap-6 ">
        <ReturnButton label="Voltar" />

        <FormBox>
          {loadingSympla ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">{loadingSympla ?? 'Carregando...'}</span>
            </div>
          ) : (
            <div className="mx-auto max-w-2xl rounded-lg p-6">
              <div className="mb-4 border-b pb-4">
                <h1 className="text-2xl font-bold">{SymplaData?.data?.name}</h1>
                <div className="mt-2 text-gray-600">
                  <p>
                    {moment(SymplaData?.data?.start_date)
                      .utcOffset('-03:00')
                      .format('DD MMM - YYYY • HH:mm')}{' '}
                    {'>'}{' '}
                    {moment(SymplaData?.data?.end_date)
                      .utcOffset('-03:00')
                      .format('DD MMM - YYYY • HH:mm')}
                  </p>
                  <p className="text-blue-600">
                    Evento presencial em{' '}
                    <a href="#" className="underline">
                      {SymplaData?.data?.address.zip_code}, {SymplaData?.data?.address.city} -
                      {SymplaData?.data?.address.state}
                    </a>
                  </p>
                </div>
              </div>

              <div className="mb-6">
                <h2 className="text-lg font-semibold">Descrição do evento</h2>
                <p className="mt-2 text-gray-700">{SymplaData?.data?.detail}</p>
              </div>

              <div>
                <h2 className="text-lg font-semibold">Política do evento</h2>
                <div className="mt-4">
                  <h3 className="font-semibold">Cancelamento de pedidos pagos</h3>
                  <p className="mt-2 text-gray-700">
                    Cancelamentos de pedidos serão aceitos até 7 dias após a compra, desde que a
                    solicitação seja enviada até 48 horas antes do início do evento.
                  </p>
                  <a
                    href={SymplaData?.data?.url}
                    target="_blank"
                    className="text-blue-600 underline"
                    rel="noreferrer"
                  >
                    Saiba mais sobre o cancelamento
                  </a>
                </div>

                <div className="mt-6">
                  <h3 className="font-semibold">Edição de participantes</h3>
                  <p className="mt-2 text-gray-700">
                    Você poderá editar o participante de um ingresso apenas uma vez. Essa opção
                    ficará disponível até 24 horas antes do início do evento.
                  </p>
                  <a
                    href={SymplaData?.data?.url}
                    target="_blank"
                    className="text-blue-600 underline"
                    rel="noreferrer"
                  >
                    Saiba como editar participantes
                  </a>
                </div>
              </div>
            </div>
          )}
        </FormBox>
      </div>
    </PageBox>
  );
}
