import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Text } from '@chakra-ui/react';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { SwitchHookForm } from '../../inputs/forms/SwitchHookForm';
import { IProfile, useProfile } from '../../../hooks/useProfile';

const schema = yup.object().shape({
  senha: yup.string().required('Senha obrigatória'),
  nova_senha: yup
    .string()
    .required('Campo obrigatório.')
    .min(6, 'Senha deve conter pelo menos 6 caracteres.')
    .max(20, 'Senha deve conter no máximo 20 caracteres.'),
  confirma_senha: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('nova_senha')], 'As senhas não são iguais'),
  check: yup.boolean().required('Campo obrigatório.'),
});

interface IForm {
  senha: string;
  nova_senha: string;
  confirma_senha: string;
  check: boolean;
}

export default function SecurityForm() {
  const { updateProfile } = useProfile();

  const { mutateAsync } = useMutation({
    mutationFn: updateProfile,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      senha: '',
      nova_senha: '',
      confirma_senha: '',
      check: false,
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync({
        senha: values.senha,
        nova_senha: values.nova_senha,
      } as unknown as IProfile);
      toast.success(`Senha editada com sucesso!`, {
        position: 'top-right',
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader title="Segurança" subtitle="Informe sua senha atual para criação de uma nova" />

        <FormBox>
          <FormProvider {...formMethods}>
            <InputForm
              name="senha"
              label="Senha atual"
              placeholder="Digite a senha atual"
              type="password"
            />
            <InputForm
              name="nova_senha"
              label="Nova senha"
              placeholder="Digite nova senha"
              type="password"
            />
            <InputForm
              name="confirma_senha"
              label="Confirme a nova senha"
              placeholder="Comfirme nova senha"
              type="password"
            />
            <div className="flex flex-row gap-1">
              <SwitchHookForm name="check" size="lg" />
              <Text className="text-secondaryText">
                Estou ciente que ao atualizar a senha, as credenciais de API serão automaticamente
                renovadas. Será necessário atualizá-las em todos os serviços que as utilizam.
              </Text>
            </div>
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="flex justify-between">
        <Button variant="outline">Cancelar</Button>
        <Button type="submit">Salvar</Button>
      </div>
    </form>
  );
}
