import React from 'react';
import SecurityForm from '../../components/forms/SecurityForm';
import { PageBox } from '../../components/pageBox';
import { PageHeader } from '../../components/PageHeader';

export default function SecurityPage() {
  return (
    <div className="mx-auto w-full lg:max-w-[1200px]">
      <PageBox>
        <div className="flex flex-col gap-2">
          <PageHeader title="Alterar senha" />
          <SecurityForm />
        </div>
      </PageBox>
    </div>
  );
}
