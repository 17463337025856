import React from 'react';
import { Heading } from '@chakra-ui/react';
import LoginForm from '../../../components/forms/LoginForm';

export function LoginPage() {
  return (
    <div className="flex h-screen bg-body">
      <div className="hidden h-full w-full bg-login_bg bg-cover bg-left sm:block" />
      <div className="grid h-screen place-items-center">
        <div className="flex flex-col items-center gap-3 px-9">
          <Heading color="text.tertiary" fontSize={24} textAlign="center">
            Acesso à Plataforma da Especialistas Inteligência Contábil
          </Heading>
          <LoginForm />
        </div>
      </div>

      {/* <Center w="100%" backgroundColor="body">
        <Container maxW="500px">
          <div className="mb-10 flex flex-col items-center gap-3">
            <Heading color="text.tertiary" fontSize={24} textAlign="center">
              Acesso à Plataforma da Especialistas Inteligência Contábil
            </Heading>
          </div>
          <LoginForm />
        </Container>
      </Center> */}
    </div>
  );
}
