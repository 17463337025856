import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IData } from '../types/shared';

export interface ISessions {
  id: number;
  usuario_id?: number;
  user_agent: string;
  sistema_operacional: string;
  dispositivo: string;
  navegador: string;
  ativo: boolean;
}

export const useSessions = () => {
  const getSessions = async (key: QueryFunctionContext): Promise<IData<ISessions[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ISessions[]>>('/api/v1/perfil/sessoes/', {
      params: filters,
    });
    return res.data;
  };
  const deleteSession = async (sessionId: string) => {
    const res = await api.delete(`/api/v1/perfil/sessoes/${sessionId}/`);
    return res.data;
  };

  return {
    getSessions,
    deleteSession,
  };
};
