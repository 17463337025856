import React from 'react';
import ProfileForm from '../../components/forms/ProfileForm';
import { PageBox } from '../../components/pageBox';
import { PageHeader } from '../../components/PageHeader';

export default function ProfilePage() {
  return (
    <div className="mx-auto w-full lg:max-w-[1200px]">
      <PageBox>
        <div className="flex flex-col gap-2">
          <PageHeader title="Editar perfil" />
          <ProfileForm />
        </div>
      </PageBox>
    </div>
  );
}
