import { Button, Text } from '@chakra-ui/react';
import { ArrowRight } from 'phosphor-react';
import React from 'react';

interface DashboardCardProps {
  title: string;
  value: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const DashboardCard = ({ title, value, children, onClick }: DashboardCardProps) => {
  return (
    <div className="flex flex-col items-center gap-3 rounded-3xl border-2 border-solid bg-body p-5 lg:flex-row">
      <div className="h-full">{children}</div>
      <div className="flex w-full flex-col gap-4">
        <Text fontWeight="bold" fontSize="16px">
          {title}
        </Text>
        <Text fontSize="14px">{value}</Text>
        <Button className="w-full gap-4" onClick={onClick}>
          Acessar <ArrowRight className="size-6" />
        </Button>
      </div>
    </div>
  );
};
